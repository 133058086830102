import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { motion } from "framer-motion"
import { getAllFeatures } from "./common/functions"
import loopStyles from 'common/styles';
// import MobileMap from 'components/Map/MobileMap';
import { MapboxFeature, MapboxFeatureDictionary } from 'common/feature';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';
// import * as firebaseui from 'firebaseui';
import firebaseConfig from 'firebaseConfig'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Map from 'components/Map/Map';
import { useDispatch, useSelector } from 'react-redux';
import { restoreUserSession } from 'store/reducers/user';
import MapView from 'components/pages/MapView/MapView';
import { updateIsMobile } from 'store/reducers/global';
import { BrowserRouter, Route, Router, Routes, redirect, useNavigate } from 'react-router-dom'
import Login from 'components/pages/Login/Login';

// let ui: any = undefined
let analytics: any = undefined
let firestoreDB : any= undefined
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  analytics = firebase.analytics();
  firebase.analytics().logEvent('app_loaded')
  firestoreDB = firebase.firestore();
}

declare global {
    interface Window { gapi: any; }
}

const App = () => {
  const auth = getAuth()
  const dispatch = useDispatch()

  const user = useSelector((state: any) => state.user.user)
  const isUserLoading = useSelector((state: any) => state.user.isLoading)

  // const [isMobileDevice, setIsMobileDevice] = useState(isMobile())
  const [mapboxFeatureDictionary, setMapboxFeatureDictionary] = useState<MapboxFeatureDictionary | undefined>(undefined)

  onAuthStateChanged(auth, (user) => {
    dispatch(restoreUserSession(user))
  })
  
  const buildmapboxFeatureDictionary = async () => {
    const [featureList] = await Promise.all([getAllFeatures()]);
    let mapboxFeatureDictionary: MapboxFeatureDictionary = {}
    featureList.forEach((feature: MapboxFeature) => {
      if (!mapboxFeatureDictionary[feature.properties!.featureID]) {
        mapboxFeatureDictionary[feature.properties!.featureID] = feature;
      }
    });
    setMapboxFeatureDictionary(mapboxFeatureDictionary);
  }

  // const startFirebaseLogin = () => {
  //   const uiconfig = {
  //     // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  //     signInFlow: 'popup',
  //     signInSuccessUrl: '',
  //     signInOptions: [
  //       firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  //       firebase.auth.EmailAuthProvider.PROVIDER_ID,
  //     ],
  //   };
  //   ui.start('#firebaseui-auth-container', uiconfig);
  // }

  useEffect(() => {
    // startFirebaseLogin();

    window.matchMedia(`(max-width: 768px)`).addEventListener('change', (ev: MediaQueryListEvent) => {
      dispatch(updateIsMobile(ev.matches))
    })
  }, [])

  useEffect(() => {
    if (isUserLoading === false) {
      if (!user?.emailVerified) {
        // user is not logged in
        if (window.location.pathname !== '/login') {
          window.location.href = '/login'
        }
      } else {
        // user is logged in
        firebase.analytics().logEvent(`User logged in`);
        buildmapboxFeatureDictionary()
        if (window.location.pathname !== '/' && window.location.pathname !== '/old') {
          window.location.href = '/'
        }
      }
    }
  }, [user])

  const loaded = mapboxFeatureDictionary !== undefined;

  return <ErrorBoundary>
      <Container id='App'>
        <BrowserRouter>
          <Routes>
            <Route path='/login'  element={<Login analytics={analytics} />} />
            <Route path='/' element={user?.emailVerified && loaded ? <MapView
                  mapboxFeatures={mapboxFeatureDictionary!}
                  analytics={analytics}
                  firestoreDB={firestoreDB}
              /> : <SpinnerContainer id='Spinner' animate={{ scale: [1.0, 1.2, 1.0], boxShadow: '800px 800px red', }} transition={{ ease: 'easeInOut', duration: 4, repeat: Infinity }}>
                <Spinner src={`${process.env.PUBLIC_URL}/hyperloopFav.png`} alt={'HyperMap Logo'} />
              </SpinnerContainer>}
            />
            <Route path='/old' element={process.env.REACT_APP_BETA_USERS?.includes(user?.email) && loaded ? 
              <Map
                mapboxFeatures={mapboxFeatureDictionary!}
                analytics={analytics}
                firestoreDB={firestoreDB}
              /> : <SpinnerContainer id='Spinner' animate={{ scale: [1.0, 1.2, 1.0], boxShadow: '800px 800px red', }} transition={{ ease: 'easeInOut', duration: 4, repeat: Infinity }}>
                <Spinner src={`${process.env.PUBLIC_URL}/hyperloopFav.png`} alt={'HyperMap Logo'} />
              </SpinnerContainer>} 
            />
          </Routes>
        </BrowserRouter>
      </Container>
    </ErrorBoundary>
}

const Spinner = styled.img`
  width: 250px;
  height: 250px;
`

const SpinnerContainer = styled(motion.div)`
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100dvh;
  align-items: center;
  justify-content: center;
  background-color: ${loopStyles.colors.primary};
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-width: -webkit-fill-available;
  min-height: -webkit-fill-available;
  overflow: hidden;
  background-color: ${loopStyles.colors.primary};
  ${loopStyles.mediaQueries.mobile} {
    max-height: 100dvh;
  }
`

export default App;
