import { createSlice } from "@reduxjs/toolkit"

const globalSlice = createSlice({
    name: 'global',
    initialState: {
        firestoreDB: undefined,
        isMobile: window.matchMedia(`(max-width: 768px)`).matches,
    },
    reducers: {
        updateIsMobile: (state, action) => {
            state.isMobile = action.payload;
        }
    }
})

export const { updateIsMobile } = globalSlice.actions;

export default globalSlice.reducer;