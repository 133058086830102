import React from 'react';
import {  withStyles } from '@material-ui/core';
import loopStyles from 'common/styles';
import styled from 'styled-components';
import { Feature } from 'common/feature';
import StatRow from './StatRow';
import YouTubeVideo from 'components/common/YouTubeVideo';

interface PointLayoutProps {
    feature: Feature,
    classes: any,
}

interface PointLayoutState {
    tabIndex: number,
}

class PointLayout extends React.Component<PointLayoutProps, PointLayoutState> {
    state = {
        tabIndex: 0
    }

    render() {
        const { feature } = this.props

        return (
            <div style={{width: '400px'}}>
                <div style={{
                    backgroundSize: '100% 100%',
                    backgroundColor: '1px solid #434343',
                }}>
                    <div style={{ margin: '10px 18px' }}>
                        <SubtitleContainer>
                            {feature.sponsoringInstitution || 'Point of Interest'}
                        </SubtitleContainer>
                        <FeatureName>{feature.name}</FeatureName>
                    </div>
                    {feature.youtube && <div style={{ height: '225px' }}>
                        <YouTubeVideo link={feature.youtube} />
                    </div>}
                    <MediaIcons>
                        {/* <MediaIcon url="" icon={<TwitterIcon/>}/> */}
                    </MediaIcons> 
                </div>
                {/* <Tabs
                    value={tabIndex}
                    onChange={this.handleChange}
                    className={classes.tabs}
                    TabIndicatorProps={{ style: { backgroundColor: 'rgba(0,0,0,0)' } }}
                >
                    <Tab className={classes.tab} label="Details" />
                </Tabs>
                {this.getTabContent(tabIndex)} */}
            </div>
        );
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabIndex: newValue });
    }

    getTabContent = (tabIndex: number) => {
        const { feature } = this.props;

        switch (tabIndex) {
            case 0:
                return <>
                    <StatRow statLabel='Founded' value={feature.Founded} />
                    <StatRow statLabel='Country' value={feature.Country} />
                    <StatRow statLabel='Address' value={feature.Address} />
                </>
            case 1:
                return <>
                </>
            case 2:
                return <>
                </>
        }
    }
}

// const Icon = styled.img({
//     marginRight: '10px',
// })

// const StatLabel = styled.div({
//     fontFamily: 'Roboto',
//     fontStyle: 'normal',
//     fontWeight: 'bold',
//     fontSize: '13px',
//     letterSpacing: '0.1px',
//     marginBottom: '10px',
//     color: '#B7B7B7',
// })

// const StatValue = styled.div({
//     fontFamily: 'Poppins',
//     fontStyle: 'normal',
//     fontWeight: 'bold',
//     fontSize: '24px',
//     letterSpacing: '2px',
//     color: '#FFFFFF',
// })

const SubtitleContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '23px',
    letterSpacing: '0.1px',
    color: '#B7B7B7',
    opacity: 0.99,
})

const MediaIcons = styled.div({
    display: 'flex',
})

const FeatureName = styled.div({
    width: 'auto',
    // height: '42px',
    // overflow: 'ellipse',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '42px',
    letterSpacing: '0.10000000149011612px',
    color: '#FFFFFF',
    opacity: 0.99,
})

const styles = {
    tab: {
        width: '100px',
        minWidth: '100px',
        color: loopStyles.colors.secondary,
        '&:selected': {
            color: '#1890ff',
        },
        opacity: 0.3,    
    },
    tabs: {
        borderBottom: '1px solid #434343',
        '&$selected': {
            color: '#1890ff',
        },    
    },
    indicator: {
      backgroundColor: 'none',  
      color: '#1890ff',
    },
}

export default withStyles(styles)(PointLayout);