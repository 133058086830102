import React from 'react';
import styled from 'styled-components';

interface PageTabProps {
  label: string,
  onClick?: any,
}

class Tab extends React.Component<PageTabProps> {
    render() {
        // const { pageType, onClick, isPro, isDisabled, selectedTab } = this.props
        const { label, onClick } = this.props;
        // const label = this.getLabel(pageType)
        // const isSelected = selectedTab === pageType;
        return (
            <Container onClick={onClick}>
                <Text>{label}</Text>
                {/* <UnderBar className="underbar"/> */}
            </Container>
        );
    }

    // getIcon = () => {
    //     // const { selectedTab, pageType } = this.props;
    //     const color = selectedTab === pageType ? loopStyles.colors.tertiary : loopStyles.colors.secondary

    //     switch (pageType){
    //         case PageType.Map:
    //             return <MapIcon fill={color}/>
    //         case PageType.Stat:
    //             return <StatsIcon fill={color}/>
    //         case PageType.Team:
    //             return <TeamsIcon fill={color}/>
    //         case PageType.IPTracker:
    //             return <IPTrackerIcon fill={color}/>
    //         case PageType.Industry:
    //             return <IndustryUpdatesIcon fill={color}/>
    //         default:
    //             return ''
    // }
// }
    // getLabel = (pageType: string) => {
    //     switch (pageType){
    //         case PageType.Map:
    //             return 'Map'
    //         case PageType.Stat:
    //             return 'Stats'
    //         case PageType.Team:
    //             return 'Teams'
    //         case PageType.IPTracker:
    //             return 'IP Tracker'
    //         case PageType.Industry:
    //             return 'Industry Updates'
    //         default:
    //             return ''
    //     }
    // }
}

const UnderBar = styled.div({
    height: '1px', 
    width: '100%', 
    '&::-webkit-transition': '0.5s ease'
})

const Text = styled.div({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.1px', 
    padding: '10px'
})

const Container = styled.div<{isDisabled?: boolean, isSelected?: boolean}>({
    display: 'flex', 
    flexDirection: 'column',
    justifyContent: 'center', 
    alignItems: 'center', 
    height: 'min-content',
    margin: '0 8px',
    cursor: 'pointer',
    userSelect: 'none',
    color: '#ffffff',
    borderRadius: '8px',
    '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.2)',
    }
    // '&:hover .underbar': {
    //     background: 'rgba(0, 180, 255,0.5)',
    //     boxShadow: '0 0 10px rgba(70,250,250,1)',
    // }
  }
)

export default Tab;