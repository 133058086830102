import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Feature, MapboxFeature, MapboxFeatureDictionary } from "common/feature";
import { getDocumentFromCollection } from "common/firestoreUtil";
import { getAllFeatures } from "common/functions";
import firebase from 'firebase/compat/app';

export const fetchMapboxFeatures = createAsyncThunk('map/fetchMapboxFeatures', async () => {
    const featureList = await getAllFeatures();
    let mapboxFeatureDictionary: MapboxFeatureDictionary = {}
    featureList.forEach((feature: MapboxFeature) => {
        if (!mapboxFeatureDictionary[feature.properties!.featureID]) {
            mapboxFeatureDictionary[feature.properties!.featureID] = feature;
        }
    });
    return mapboxFeatureDictionary
});

type SelectFeatureViaIDParams = {
    featureID?: string,
    segmentRouteState?: any[],
    unselectIfDuplicate?: boolean,
}

export const selectFeatureViaID = createAsyncThunk('map/selectFeatureViaID', async (params: SelectFeatureViaIDParams | undefined, { getState }) => {
    const state = getState() as any;
    const selectedFeatureData = state.map.selectedFeatureData;
    
    // if featureID is undefined, or if unselectIfDuplicate is true and featureID matches selected feature ID
    console.log(params)
    if (!params || (params?.unselectIfDuplicate === true && params?.featureID === selectedFeatureData?.id)) {
        return undefined
    }
    
    const { featureID, segmentRouteState, unselectIfDuplicate } = params;

    const firestoreDB = firebase.firestore();
    
    const document: Feature | undefined = await getDocumentFromCollection(firestoreDB, "features", featureID)
    console.log(document)
    return {
        feature: document,
    };
});

const mapSlice = createSlice({
    name: 'map',
    initialState: {
        selectedFeatureData: undefined,
        selectedSectionIndex: undefined,
        segmentRouteState: undefined,
        mapboxFeatures: undefined,
        zoom: false,
    },
    reducers: {
        zoomToSelection: (state: any) => {
            state.zoom = !state.zoom
        },
        updateSegmentRouteState: (state: any, action) => {
            let segmentStateCopy = [...state.segmentRouteState]
            segmentStateCopy[action.payload.sectionIndex] = action.payload.optionIndex
            state.segmentRouteState = segmentStateCopy
        },
        updateSelectedSectionIndex: (state, action) => {
            state.selectedSectionIndex = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMapboxFeatures.pending, (state) => {
                // state.status = 'loading';
            })
            .addCase(fetchMapboxFeatures.fulfilled, (state: any, action) => {
                // state.status = 'succeeded';
                state.mapboxFeatures = action.payload;
            })
            .addCase(fetchMapboxFeatures.rejected, (state, action) => {
                // state.status = 'failed';
                // state.error = action.error.message;
            });
        builder
            .addCase(selectFeatureViaID.pending, (state) => {
                // state.status = 'loading';
            })
            .addCase(selectFeatureViaID.fulfilled, (state: any, action: any) => {
                // state.status = 'succeeded';
                state.selectedSectionIndex = undefined
                state.segmentRouteState = action.payload?.feature?.isSegmentRoute ? new Array(action.payload.feature.segmentStructure.length).fill(0) : undefined
                state.selectedFeatureData = action.payload?.feature
            })
            .addCase(selectFeatureViaID.rejected, (state, action) => {
                // state.status = 'failed';
                // state.error = action.error.message;
            });
    },
})

export const { updateSegmentRouteState, updateSelectedSectionIndex, zoomToSelection } = mapSlice.actions;
export default mapSlice.reducer;