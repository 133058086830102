import React from 'react';
import { Button as MatUIButton, Typography, withStyles } from '@material-ui/core';
import loopStyles from 'common/styles';

export enum ButtonSize {
    Small="Small",
    Large="Large"
}

interface ButtonProps {
    onClick?: any,
    size: ButtonSize,
    label: string,
    classes: any,
    icon?: string,
}

class Button extends React.Component<ButtonProps> {
    render() {
        const { onClick, label, classes, icon, size } = this.props

        return (
            <MatUIButton className={size === ButtonSize.Large ? classes.buttonLarge : classes.buttonSmall} onClick={onClick} >
                {icon && 
                     <img src={`${process.env.PUBLIC_URL}/icons/${icon}`} alt={'img'} style={{marginRight: '8px'}} />
                }
                <Typography className={classes.label} style={{textTransform: 'none'}}>{label}</Typography>
            </MatUIButton>
        );
    }
}

const styles = {
    label: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 100,
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.1px',
        color: '#A4A4A5;',
    },
    buttonLarge: {
        display: 'flex',
        height: '45px',
        background: 'rgba(196, 196, 196, 0.11)',
        border: `1px solid ${loopStyles.colors.tertiary}`,
        backdropFilter: 'blur(6px)',
        borderRadius: '5px',
        padding: '0 15px'
    },
    buttonSmall: {
        display: 'flex',
        width: '79px',
        height: '33px',
        background: 'rgba(196, 196, 196, 0.11)',
        borderRadius: '5px',
        padding: '0 15px'
    },
};

export default withStyles(styles)(Button);