import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import loopStyles from 'common/styles';
import styled from 'styled-components';
import { Feature } from 'common/feature';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import YouTubeVideo from 'components/common/YouTubeVideo';
import { ReactComponent as TwitterIcon } from 'common/svgIcons/twitter.svg';
import { ReactComponent as FacebookIcon } from 'common/svgIcons/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'common/svgIcons/linkedin.svg';
import { ReactComponent as InstagramIcon } from 'common/svgIcons/instagram.svg';
import { ReactComponent as LinkIcon } from 'common/svgIcons/link.svg';

// import { ZoomInIcon } from '@material-ui/icons/ZoomIn';
import { ZoomIn as ZoomInIcon, Close as CloseIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeatureViaID, updateSegmentRouteState, updateSelectedSectionIndex } from 'store/reducers/map';
import { AnimatePresence, motion } from 'framer-motion';
import MediaIcon from 'components/Card/Layouts/FeatureLayout/MediaIcon';

interface RouteLayoutProps {
    feature: Feature,
    classes: any,
}

const RouteLayout = (props: RouteLayoutProps) => {
    // Redux state
    const dispatch = useDispatch();
    const segmentRouteState = useSelector((state: any) => state.map.segmentRouteState)
    const menuData = useSelector((state: any) => state.menu.data)

    // Local State
    const [tabIndex, setTabIndex] = useState(0)
    const [isImperialUnits, setIsImperialUnits] = useState(true)

    const { feature } = props;

    const isDetailsEmpty = feature.description === '' || feature.description === undefined

    useEffect(() => {
        setTabIndex(0)
    }, [props.feature])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue)
    }

    const getStatusText = () => {
        switch (feature?.status) {
            case 'Operational':
                return 'Route Operational'
            case 'Proposal':
                return 'Proposal Submitted'
            case 'Approval':
                return 'Awaiting Approval'
            case 'Construction':
                return 'Under Construction'
            default:
                return feature?.status
        }
    }

    const getTabContent = (tabIndex: number) => {
        const { feature } = props;

        if (feature === undefined) { return }
        

        let content = undefined

        switch (tabIndex) {
            case 0:
                content = <DetailsContainer>
                    {!isDetailsEmpty && <>
                        {feature.description && <div style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '23px',
                            letterSpacing: '0.1px',
                            color: '#B7B7B7',
                            margin: '10px 20px',
                            overflow: 'hidden',
                        }}>
                            {feature.description}
                        </div>}
                    </>}
                </DetailsContainer>
                break;
        }

        return content
    }

    const { classes } = props;

    // 85 for top padding, 15 for bottom
    return <div style={{ height: 'min-content', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
        <div style={{ display: 'flex', padding: '10px 20px' }}>
            {feature.facebook && <MediaIcon icon={<FacebookIcon/>} url={feature.facebook} />}
            {feature.instagram && <MediaIcon icon={<InstagramIcon/>} url={feature.instagram} />}
            {feature.linkedIn && <MediaIcon icon={<LinkedinIcon/>} url={feature.linkedIn} />}
            {feature.twitter && <MediaIcon icon={<TwitterIcon/>} url={feature.twitter} />}
            {feature.website && <MediaIcon icon={<LinkIcon/>} url={feature.website} />}
        </div>
        <div style={{
            backgroundSize: '100% 100%',
            backgroundColor: '1px solid #434343',
            width: '100%',
        }}>
            {feature?.youtube && <div style={{ height: '225px' }}>
                <YouTubeVideo link={feature?.youtube} />
            </div>}
        </div>  
        {!isDetailsEmpty && <>
            <Tabs
                value={tabIndex}
                onChange={handleChange}
                className={classes.tabs}
                TabIndicatorProps={{ style: { backgroundColor: loopStyles.colors.tertiary } }}
            >
                <Tab selected={tabIndex === 0} className={classes.tab} label="Details" />
            </Tabs>
            {getTabContent(tabIndex)}
        </>}
    </div>
}

const EmptyDetailsLabel = styled.div`
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1px;
    margin-bottom: 10px;
    color: #B7B7B7;
`

const Toggle = styled.div`
    display: flex;
    height: 30px;
    border-radius: 15px;
    border: 1px solid ${loopStyles.colors.tertiary};
    color: ${loopStyles.colors.tertiary};
    width: 100%;
    margin: 8px 0;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
`

type ToggleItemProps = {
    isSelected: boolean
}

const ToggleItem = styled.div<ToggleItemProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    ${props => props.isSelected &&  `
    color: black;
    ${loopStyles.transition.animationStyles}
    background-color: ${loopStyles.colors.tertiary};
    `}
    :not(:last-child) {
        border-right: 1px solid ${loopStyles.colors.tertiary};
    }
`

const DetailsContainer = styled.div`
    width: 100%;
    padding: 10px 0;
    // min-height: 260px;
    height: min-content;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

const OptionsContainer = styled.div`
    width: 100%;
    // min-height: 240px;
    flex: 1;
    // height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 0;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

type OptionBubbleProps = {
    selected: boolean
}

const OptionBubble = styled.div<OptionBubbleProps>`
    width: 20px;
    height: 20px;
    border: 1px solid ${loopStyles.colors.tertiary};
    border-radius: 50%;
    background-color: ${props => props.selected ? loopStyles.colors.tertiary : loopStyles.colors.primary};
    color: ${props => props.selected ? loopStyles.colors.primary : loopStyles.colors.secondary};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    ${loopStyles.transition.animationStyles}
    &:hover {
       color: ${loopStyles.colors.primary}; 
       background-color: ${loopStyles.colors.tertiary};
    }
`

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    height: 32px;
    width: 32px;
`

const ZoomButton = styled.div`
    display: flex;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    align-items: center;
    padding: 6px 8px;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    user-select: none;
    white-space: nowrap;
    &:hover {
        background-color: rgba(0,0,0,0.3);
    }
    ${loopStyles.transition.animationStyles}
`

const IconWrapper = styled.div`
    display: flex;
    border-radius: 8px;
    color: #ffffff;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    user-select: none;
    &:hover {
        background-color: rgba(0,0,0,0.3);
    }
    ${loopStyles.transition.animationStyles}
`

const StatLabel = styled.div({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '13px',
    letterSpacing: '0.1px',
    marginBottom: '10px',
    color: '#B7B7B7',
})

// const PlayVideoText = styled.div({
//     fontFamily: 'Roboto',
//     fontStyle: 'normal',
//     fontWeight: 'bold',
//     fontSize: '14px',
//     lineHeight: '23px',
//     /* identical to box height, or 164% */
//     letterSpacing: '0.1px',
//     color: '#6FF3FC',
//     marginLeft: '300px',
//     cursor: 'pointer'
// })

const StatValue = styled.div<{unit?: string}>({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        letterSpacing: '2px',
        color: '#FFFFFF',
    },
    ({ unit }) => ({
        "&:after": {
            position: 'absolute',
            height: '35px',
            content: `${unit ? `"${unit}"` : ""}`,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '23px',
            letterSpacing: '0.1px',
            color: '#B7B7B7',
            marginLeft: '4px',
            marginTop: '7px',
        },
    })
)

const SubtitleContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0',
    height: '32px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '23px',
    letterSpacing: '0.1px',
    color: '#B7B7B7',
    opacity: 0.99,
})

const FeatureName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: min-content;
    max-width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
`

const styles = {
    tab: {
        width: '100px',
        minWidth: '100px',
        borderRadius: '10px 10px 0 0',
        borderLeft: `1px solid #434343`,
        borderRight: `1px solid #434343`,
        borderTop: `1px solid #434343`,
        color: loopStyles.colors.secondary,
        margin: '0 2px',
        transition: 'all 0.2s ease-in',
        '&.Mui-selected, &:hover': {
            borderLeft: `1px solid ${loopStyles.colors.secondary}`,
            borderRight: `1px solid ${loopStyles.colors.secondary}`,
            borderTop: `1px solid ${loopStyles.colors.secondary}`,
        },
    },
    tabs: {
        marginTop: '8px',
        borderBottom: '1px solid #434343',
        color: 'white',
        '&:selected': {
            color: '#1260cc',
        },
    },
    indicator: {
      backgroundColor: 'none',  
      color: '#1890ff',
    },
}

export default withStyles(styles)(RouteLayout);