import React from 'react';

interface YouTubeVideoProps {
    link: string;
}

class YouTubeVideo extends React.Component<YouTubeVideoProps> {
    render() {
        const { link } = this.props

        return (
            <iframe width="100%" height="100%" src={link.replace('/watch', '/embed').replace('?v=', '/')} frameBorder="0" allowFullScreen title='YouTube' />
        );
    }
}

export default YouTubeVideo;