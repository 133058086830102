const loopStyles: any = {
    motion: {
        fadeInOut: {
            initial: {
                opacity: 0,
            },
            exit: {
                opacity: 0,
            },
            animate: {
                opacity: 1,
            },
            transition: {
                duration: 0.15
            }
        }
    },
    scrollbar: {
        default: `
            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
                background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #00F7FE;
                border: 4px solid transparent;
                border-radius: 16px;
            }
        `
    },
    mediaQueries: {
        desktop: `@media screen and (min-width: 768px)`,
        mobile: `@media screen and (max-width: 768px)`,
    },
    transition: {
        animationStyles: `
            -moz-transition: all .2s ease-in;
            -o-transition: all .2s ease-in;
            -webkit-transition: all .2s ease-in;
            transition: all .2s ease-in;
        `
    },
    colors: {
        primary: '#222123',
        secondary: '#E8E8E8',
        disabled: '#4F4D52',
        tertiary: '#00F7FE',
        sidebarGradient: 'linear-gradient(180deg, rgba(34,33,35,1) 0%, rgba(18,18,18,1) 100%)',
    },
    'VHO': {
        colors: {
            primary: "#7412fd",
            secondary: "#603c86",
            font: '#ffffff',
        },
    },
    'HTT': {
        colors: {
            primary: "#fe0606",
            secondary: "#fcfdf8",
            font: '#ffffff',
        },
    },
    'HP': {
        colors: {
            primary: "#fb8209",
            secondary: "#ffffff",
            font: '#ffffff',
        }
    },
    'TP': {
        colors: {
            primary: "#f604f2",
            secondary: "#ffffff",
            font: '#ffffff',
        },
    },
    'ZEL': {
        colors: {
            primary: "#0b03dd",
            secondary: "#000000",
            font: '#ffffff',
        },
    },
    'SP': {
        colors: {
            primary: "#b61f13",
            secondary: "#e01f1f",
            font: '#ffffff',
        },
    },
    'RL': {
        colors: {
            primary: "#00ecec",
            secondary: "#ffffff",
            font: '#ffffff',
        },
    },
    'TUM': {
        colors: {
            primary: "#66a3d6",
            secondary: "#b1b3b4",
            font: '#ffffff',
        },
    },
    'EL': {
        colors: {
            primary: "#e0081f",
            secondary: "#b1b3b4",
            font: '#ffffff',
        },
    },
    'DGW': {
        colors: {
            primary: "#22d88f",
            secondary: "#ffffff",
            font: '#ffffff',
        },
    },
    'HAR': {
        colors: {
            primary: "#13f1d7",
            secondary: "#ffffff",
            font: '#ffffff',
        },
    },
    'STML': {
        colors: {
            primary: "#42f4dc",
            secondary: "#ffffff",
            font: '#ffffff',
        },
    },
    // 'HCTHL': {
    //     colors: {
    //         primary: "",
    //         secondary: "",
    //         font: '#ffffff',
    //     },
    // },
    'BOR': {
        colors: {
            primary: "#ffffff",
            font: "#000000",
        },
    },
    'Academic Team': {
        colors: {
            primary: "#000000",
        }
    },
    'teams': {
        colors: {
            primary: '#00F7FE',
            font: '#000000',
        },
    },
}

export default loopStyles;