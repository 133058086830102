import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import loopStyles from 'common/styles';
import styled from 'styled-components';
import { Feature } from 'common/feature';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MediaIcon from './MediaIcon';
import StatRow from './StatRow'
import { ReactComponent as TwitterIcon } from 'common/svgIcons/twitter.svg';
import { ReactComponent as FacebookIcon } from 'common/svgIcons/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'common/svgIcons/linkedin.svg';
import { ReactComponent as InstagramIcon } from 'common/svgIcons/instagram.svg';
import { ReactComponent as LinkIcon } from 'common/svgIcons/link.svg';
import YouTubeVideo from 'components/common/YouTubeVideo';
import { ZoomIn as ZoomInIcon, Close as CloseIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { selectFeatureViaID } from 'store/reducers/map';

interface TeamLayoutProps {
    feature: Feature,
    classes: any,
}

interface TeamLayoutState {
    tabIndex: number,
    showVideo: boolean,
}

const TeamLayout = (props: TeamLayoutProps) => {
    const dispatch = useDispatch()
    const [tabIndex, setTabIndex] = useState(0)
    const [showVideo, setShowVideo] = useState(false)
    
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    }

    const getTabContent = (tabIndex: number) => {
        const { feature } = props;

        return <div style={{
            width: '100%',
        }}>
            <StatRow statLabel="Founded" value={feature.founded}/>
            <StatRow statLabel="Country" value={feature.country}/>
            <StatRow statLabel="Address" value={feature.address}/>
        </div>

        // switch (tabIndex) {
        //     case 0:
        //         return <></> /*<StatRow statLabel='2019 SpaceX' value='Paricipant' />*/
        //     case 1:
        //         return <>Routes</>
        //     case 2:
        //         return <>Documents</>
        // }
    }

    const { feature, classes } = props

    return (
        <div style={{width: '400px', maxWidth: '400px',}}>
            <div style={{
                backgroundSize: '100% 100%',
                backgroundColor: '1px solid #434343',
                
            }}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ margin: '10px 18px 0' }}>
                        <SubtitleContainer>
                            <Icon src={`${process.env.PUBLIC_URL}/AcademicTeams.png`} alt={'img'} style={{width: '20px', height: 'auto', fill: '#B7B7B7'}} />
                            Hyperloop Team
                        </SubtitleContainer>
                        <FeatureName>{feature.name}</FeatureName>
                    </div>
                    <IconWrapper onClick={() => { dispatch(selectFeatureViaID()) }}>
                        <CloseIcon/>
                    </IconWrapper>
                </div>
                {feature.youtube && <div style={{ height: '225px' }}>
                    <YouTubeVideo link={feature.youtube} />
                </div>}
                <MediaIcons>
                    {feature.facebook && <MediaIcon icon={<FacebookIcon/>} url={feature.facebook} />}
                    {feature.instagram && <MediaIcon icon={<InstagramIcon/>} url={feature.instagram} />}
                    {feature.linkedIn && <MediaIcon icon={<LinkedinIcon/>} url={feature.linkedIn} />}
                    {feature.twitter && <MediaIcon icon={<TwitterIcon/>} url={feature.twitter} />}
                    {feature.website && <MediaIcon icon={<LinkIcon/>} url={feature.website} />}
                </MediaIcons>
            </div>
            <Tabs
                value={tabIndex}
                onChange={handleChange}
                className={classes.tabs}
                TabIndicatorProps={{ style: { backgroundColor: 'rgba(0,0,0,0)' } }}
            >
                <Tab className={classes.tab} label="Details" />
                {/* <Tab className={classes.tab} label="Routes" />
                <Tab className={classes.tab} label="Documents" /> */}
            </Tabs>
            {getTabContent(tabIndex)}
        </div>
    );
}

// const PlayVideoText = styled.div({
//     fontFamily: 'Roboto',
//     fontStyle: 'normal',
//     fontWeight: 'bold',
//     fontSize: '14px',
//     lineHeight: '23px',
//     /* identical to box height, or 164% */
//     letterSpacing: '0.1px',
//     color: '#6FF3FC',
//     marginLeft: '300px',
//     cursor: 'pointer'
// })

const Icon = styled.img({
    marginRight: '10px',
})

const IconWrapper = styled.div`
    display: flex;
    border-radius: 8px;
    color: #ffffff;
    align-items: center;
    height: fit-content;
    margin-right: 20px;
    margin-top: 8px;
    padding: 4px;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    user-select: none;
    &:hover {
        background-color: rgba(0,0,0,0.3);
    }
    ${loopStyles.transition.animationStyles}
`

const SubtitleContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '23px',
    letterSpacing: '0.1px',
    color: '#B7B7B7',
    opacity: 0.99,
})

const MediaIcons = styled.div({
    display: 'flex',
    paddingLeft: '16px',
    paddingTop: '8px',
})

const FeatureName = styled.div({
    width: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '42px',
    letterSpacing: '0.1px',
    color: '#FFFFFF',
})

const styles = {
    tab: {
        width: '100px',
        minWidth: '100px',
        color: loopStyles.colors.secondary,
        '&:selected': {
            color: '#1890ff',
        },    
    },
    tabs: {
        '&$selected': {
            color: '#1890ff',
        },    
    },
    indicator: {
      backgroundColor: 'none',  
      color: '#1890ff',
    },
}

export default withStyles(styles)(TeamLayout);