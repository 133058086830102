import { createSlice } from "@reduxjs/toolkit"

const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        company: [],
        status: [],
        teams: true,
    },
    reducers: {
        updateFilter: (state: any, action) => {
            state[action.payload.key] = action.payload.filter
        }
    }
})

export const { updateFilter } = filtersSlice.actions;
export default filtersSlice.reducer;