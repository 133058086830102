import React from 'react';
import styled from 'styled-components';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from '@material-ui/core';
import loopStyles from 'common/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


interface FAQLayoutProps {
    // companyType: CompanyType,
    classes: any,
}

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#464648',
        borderRadius: '5px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: "bold" as "bold",
        fontSize: '17px',
        lineHeight: '23px',
        letterSpacing: '0.1px',
        color: '#E6E6E6',
        opacity: 0.99,
        minHeight: 56,
        "&$expanded": {
            margin: 0,
            minHeight: 56
      }
    },
    content: {
      "&$expanded": {
        margin: "12px 0"
      }
    },
    expanded: {}
  })(MuiAccordionSummary);

class FAQLayout extends React.Component<FAQLayoutProps> {
    render() {
        const { classes } = this.props

        return (
            <Container>
                {/* <Header>FAQ</Header> */}
                <Title>Frequently Asked Questions</Title>
                <Text>We’ve put together all frequent questions we recieve from our users and provided answers to them below:</Text>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            What is Hyper Map?
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            Hyper Map is an interactive map application designed to track all existing and potential hyperloop progress.
                            Hyper Map’s goal is to aggregate data, content, and information on the hyperloop industry in order to empower stakeholders, academia, enthusiasts, media, and the general public. 
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            Is Hyper Map affiliated with any Hyperloop Companies?
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            Hyper Map is independently owned and managed and is NOT affiliated with any of the hyperloop companies, academic or competitive teams, government agencies, or industry stakeholders/partners. Hyper Map is intended to be a trusted third-party source for all information related to the hyperloop.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            Without affilitation to the Hyperloop companies, what is the Hyper Map mission?
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            We see a burgeoning industry on the cusp on bettering our planet. The Hyper Map team believes there is a significant opportunity to inform and update the public on this revolutionizing technology.

                            It’s a new technology, which brings A LOT of questions, skepticism, and fear. Our goal is to empower and bring clarity to the industry by becoming a reliable, trusted source of truth for everything related to hyperloop.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            Are any of the routes you display actually approved for construction?
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            At this time, no routes have been approved to commence construction. Once a route has been fully cleared and approved, we will mark appropriately. Until then, please note the following disclaimer:

                            “Hyper Map is not associated or affiliated with any hyperloop companies or government institutions. Routes displayed on this map are for informational purposes only and subject to change. None of the route proposals have been approved by appropriate authorities unless otherwise noted.”
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            What are your sources for the data you display on your application?
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            All information displayed on our hyperloop interactive map is public record.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            How do I make a correction or suggestion?
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            We welcome your help! If you see anything that is outdated or incorrect or you have suggestions on how to make our application better, please email us at contact@hypermap.co or tweet us at @hyper_map
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            What exactly is the Hyperloop? 
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            Hyperloop is a proposed mode of transportation for passenger and freight in steel-like tubes over long distances. Hyperloop is recognized as the “5th mode of transportation.” 

                            Hyperloop is safer, faster and more cost effective than current modes of transportation. Additionally, Hyperloop is immune to weather, self-powering, and with speeds up to 700+ MPH, it is ideal for transportation between cities less than 900 miles apart.

                            A pod would use a low-pressure tube and accelerate gradually using electric propulsion and floats above the track using magnetic levitation.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            Is Elon Musk affiliated with a hyperloop company?
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            Elon Musk published a whitepaper Hyperloop Alpha in 2013 to open source the hyperloop concept into reality. Elon is not affiliated or invested in any of the prominent hyperloop companies. Musk does, however, host an annual hyperloop competition to further the R&D of the industry overall at SpaceX HQ in Los Angeles, CA. 

                            Elon founded a separate company, the Boring Company—Hyper Map tracks this company as well. The Boring Company is NOT a hyperloop company. The Boring Company is focused on subterranean tunnel construction to solve for intra-city traffic congestion. Vehicles will be able to travel up to 120 MPH.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            When will hyperloop be available to ride? 
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            Over the next decade, it’s likely that hyperloop will begin construction on many routes both in the U.S and abroad. Construction estimates are anywhere from 5-10 years. Most of these constructions will have multiple phases—thus increasing the likelihood that you could ride a portion of one route in the near future. Many regulatory approvals need to be cleared first, but it’s likely that we will see at least a portion of a route operational between 2025-2027 somewhere on the globe.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            Will hyperloop be expensive like an airline ticket?  
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                        The goal of every hyperloop company and government agency is to ensure that a hyperloop ticket is affordable. High ticket costs would price out the majority of riders thus preventing high ridership adoption. Current estimates range from $30-$60 for a one-way ticket. Again, nothing is set and this could vary wildly depending on the route. This is just what we have seen from initial studies.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            Does hyperloop go underground?
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            All current proposals for hyperloop are above ground. Hyperloop could go underground but this would exponentially increase the price tag on this new technology.

                            “The Loop” created by the Boring Company goes underground and is intra-city or smaller distances than hyperloop.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            How fast could the hyperloop go?
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            Estimates vary between hyperloop companies. Most estimate between 600-700 MPH.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            How many people could a hyperloop pod hold? 
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            It is estimated that one hyperloop pod would hold up to 28 people
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            What types of government regulatory approvals are needed for hyperloop?
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            TBD. There is still much to determine when it comes to government regulation. This has always been seen as a major obstacle to the construction of hyperloop.

                            It is a great sign that government’s all around the world are mobilizing in an effort to meet the technology head on. The specific steps to government regulatory approval are still being pioneered and will be country specific. 
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} classes={{ expanded: classes.expanded }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#92929D'}} />} >
                            Where can I learn more information about hyperloop?
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            We recommend navigating our application to view each of the company’s websites, research/academic team’s websites, and reviewing curated content on the hyperloop industry. Follow our social media pages, connect, and share hyperloop content. Hyperloop will change the world for the better, let’s embrace and empower it.
                        </AccordionDetails>
                    </Accordion>
                </div>
                {/* <img src={`${process.env.PUBLIC_URL}/images/${companyType}.png`} alt={'img'} style={{width: '319px', height: '141px' }} />
                <Header>{companyType === CompanyType.Loop ? loopHeader : hyperloopHeader}</Header>
                <Text>{companyType === CompanyType.Loop ? loopText : hyperloopText}</Text> */}
            </Container>
        );
    }
}

const List = styled.div({
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    overflowY: 'scroll', 
    height: '465px',
    '&::-webkit-scrollbar': {
        width: '0.4em'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: loopStyles.colors.tertiary,
        border: '4px solid transparent',
        borderRadius: '16px',
    },
})

const Header = styled.div({
    margin: '12px 0 0 20px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '21px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#828286',
})

const Title = styled.div({
    margin: '10px 0 0 20px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    letterSpacing: '0.1px',
    color: '#FFFFFF',
    opacity: 0.99
})

const Text = styled.div({
    margin: '12px 20px 20px 20px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.1px',
    color: '#B7B7B7',
    opacity: 0.99,
})

const Container = styled.div`
    width: 100%;
    height: 100%;
    user-select: none;
    overflow-y: scroll;
    overflow-x: hidden;
`

const styles = {
    accordion: {
        width: '80%',
        marginBottom: '12px',
        backgroundColor: '#464648',
        borderRadius: '5px',
        height: '100%',
        color: '#E6E6E6',
        '&:after': {
            content: "",
        },
    },
    details: {
        background: '#3D3D3F',
        borderRadius: '0 0 5px 5px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal' as 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
        color: '#B7B7B7',
        opacity: 0.99,
        padding: '14px 20px',
    },
    expanded: {
        "&$expanded": {
            margin: '0 0 12px 0',
            borderRadius: '5px',
            '&:after': {
                content: "",
            },
        }
    },
    expandedSummary: {
        minHeight: '0px'
    }
}

export default withStyles(styles)(FAQLayout);