import React, { useState } from 'react';
import styled from "styled-components";
import { withStyles } from '@material-ui/core/styles';
import {
    Collapse,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import loopStyles from 'common/styles'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { drawerWidth } from '../../../RouteSidebar/RouteSidebar';
import { Feature } from 'common/feature';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeatureViaID } from 'store/reducers/map';
import { AnimatePresence, motion } from 'framer-motion';

interface RouteFeatureGroupProps {
    classes: any,
    label: any,
    secondaryAction?: any,
    selectedFeatureID?: string | undefined,
    value?: any,
    children?: any,
    tooltipMessage?: any,
    icon: string,
    company: any,
}

const RouteFeatureGroup = (props: RouteFeatureGroupProps) => {
    // Redux state
    const dispatch = useDispatch();
    const filters = useSelector((state: any) => state.filters)

    // Local state
    const [isOpen, setIsOpen] = useState(false)
    const [showRoutesOrPoints, setShowRoutesOrPoints] = useState(true)

    const handleSecondaryAction = () => {
        const { value, secondaryAction } = props;
        secondaryAction && secondaryAction(value);
    }

    const toggleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    const handleRoutesClick = () => {
       setShowRoutesOrPoints(true)
    }

    const handleOfficesClick = () => {
        setShowRoutesOrPoints(false)
    }

    const mapRouteOrPointToListItem = (feature: Feature): React.ReactElement => {
        const { classes, selectedFeatureID, value } = props;
        const selected = feature.featureID === selectedFeatureID;
        const companyStyles = loopStyles[value];

        const selectFeature = () => {
            dispatch(selectFeatureViaID({ featureID: feature.featureID }))
        }

        return (
            <ListItem key={`featureListItem:${feature.featureID}`} className={classes.selected} onClick={selectFeature} >
                <IconButton className={classes.childIcon} style={{color: companyStyles ? companyStyles.colors.primary : loopStyles.colors.tertiary}} onClick={selectFeature}>
                    {selected ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                </IconButton> 
                <ListItemText 
                    disableTypography
                    className={classes.listItemText}
                    primary={<Typography className={classes.childLabel}>{feature.name}</Typography>}
                />
            </ListItem>
        )
    }

    const { classes, label, icon, value, selectedFeatureID } = props;

    const companyStyles = loopStyles[value];
    const selected = value === selectedFeatureID;

    const filterVisibleFeatures = (feature: any) => {
        // console.log(filters.status, feature, filters.status.has(feature.status))
        if (filters.status.length > 0 && !filters.status.includes(feature.status)) {
            return false
        }

        return true
    }

    // need to synthesize featureType values
    const featureItems = (showRoutesOrPoints ? props.company.routes : props.company.points).filter(filterVisibleFeatures).map(mapRouteOrPointToListItem)
    
    return (
    (props.company.points.length > 0 || props.company.routes.length > 0) ? <motion.div initial={{opacity: 0, height: 0, marginTop: 0}} exit={{opacity: 0, height: 0, marginTop: 0}} animate={{opacity: 1, height: 'auto', marginTop: '10px'}} style={{ width: `${drawerWidth}px`, ...(isOpen && selectedStyles) }}>
        <ListItem button className={classes.listItem}>
            <div style={{display: 'flex',justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%'}}onClick={toggleIsOpen}>
                <ListItemIcon className={classes.listItemIcon}>
                    <AnimatePresence exitBeforeEnter>
                        {isOpen ? <motion.div key='less' {...loopStyles.motion.fadeInOut}><ExpandLess /></motion.div> : <motion.div {...loopStyles.motion.fadeInOut} key='more'><ExpandMore /></motion.div>}
                    </AnimatePresence>
                </ListItemIcon>
                <div style={{display: 'flex', alignItems: 'center', width: '30px', height: '100%', justifyContent: 'center', marginRight: '10px', color: isOpen ? '#FFFFFF' : ''}}>
                    <img src={`${process.env.PUBLIC_URL}/icons/companyIcons/${icon}`} alt={'img'} style={{ maxWidth: '24px', }}/>
                </div>
                <Text>
                    <ListItemText 
                        disableTypography
                        primary={<Typography className={classes.label} style={{color: isOpen ? '#FFFFFF' : ''}}>{label}</Typography>}
                    />
                    {/* <Underline color={color} /> */}
                </Text>
                </div>
            {/* {tooltipMessage && <div style={{ marginLeft: '12px' }}>
                <Tooltip message={tooltipMessage} />
            </div>} */}
            <IconButton className={classes.childIcon} style={{color: companyStyles ? companyStyles.colors.primary : loopStyles.colors.tertiary}} onClick={handleSecondaryAction}>
                {selected ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
            </IconButton> 
            {/* {secondaryAction && <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments" onClick={this.handleSecondaryAction} className={classes.filterButtonBorder} style={{ border: `1px solid ${color}`, }}>
                    {selectedFeatureID === value ? <RadioButtonCheckedIcon className={classes.filterButton} /> : <RadioButtonUncheckedIcon className={classes.filterButton} />}
                </IconButton>
            </ListItemSecondaryAction>} */}
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit className={classes.collapse}>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <FilterTab showRoutesOrOffices={showRoutesOrPoints} onClick={handleRoutesClick}>
                    {`Routes`}
                </FilterTab>
                <FilterTab showRoutesOrOffices={!showRoutesOrPoints} onClick={handleOfficesClick}>
                    {`Offices`}
                </FilterTab>
            </div>
            {featureItems.length ? featureItems : <EmptyMessage>{`There are no ${showRoutesOrPoints ? 'routes' : 'offices' } to display.`}</EmptyMessage>}
        </Collapse>
    </motion.div> : <div/>);
}

const EmptyMessage = styled.div({
    width: '100%',
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#B5B5BE',
    fontSize: '13px',
    lineHeight: '21px',
    letterSpacing: '0.1px',
})

const FilterTab = styled.div<{showRoutesOrOffices: boolean}>({
    width: '80px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '21px',
    letterSpacing: '0.1px',
    color: '#B5B5BE',
    cursor: 'pointer',
    margin: '0 8px 8px',
    transition: 'all 0.2s ease-in',
    }, ({ showRoutesOrOffices }) => ({
        border: showRoutesOrOffices ? `1px solid ${loopStyles.colors.tertiary}` : '1px solid #313132',
        background: showRoutesOrOffices ? '#1A191B' : 'none',
        fontWeight: showRoutesOrOffices ? 600 : 500,
        opacity: showRoutesOrOffices ? 1 : 0.5,
    })
)

const Text = styled.div({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
})

const selectedStyles: any = {
    borderRadius: '5px',
    background: 'rgba(15, 14, 14, 0.25)',
}

const styles = {
    root: {
        display: 'flex',
    },
    collapse: {
        marginTop: '10px',
    },
    childIcon: {
        // color: loopStyles.colors.secondary,
        height: '100%',
        width: '60px',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: `rgba(0,0,0,0)`
        }
    },
    list: {
        width: '100%',
        maxWidth: 360,
        color: '#ffffff',
    },
    listItem: {
        padding: 0,
        height: '40px',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)',
        }
    },
    listItemText: {
        height: '100%',
    },
    listItemIcon: {
        padding: '0 10px',
        width: '25px',
        minWidth: '0',
        color: '#92929D'
    },
    nested: {
        color: '#777777'
    },
    routeList: {

    },
    label: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        // lineHeight: '21px',
        letterSpacing: '0.1px',
        color: '#B5B5BE',
    },
    childLabel: {
        display: 'flex',
        height: '100%',
        paddingLeft: '20px',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        // lineHeight: '21px',
        letterSpacing: '0.1px',
    },
    unselected: {
        color: '#777777',
    },
    colorPrimary: {
        backgroundColor: loopStyles.colors.tertiary,
    },
    barColorPrimary: {
        backgroundColor: loopStyles.colors.primary,
    },
    selected: {
        height: '50px',
        padding: 0,
        color: '#B5B5BE',
        cursor: 'pointer',
        opacity: 1,
        'user-select': 'none',
        borderLeft: `2px solid transparent`,
        transition: 'all .2s ease-in',
        '&:hover': {
            color: `${loopStyles.colors.tertiary}`,
            borderLeft: `2px solid ${loopStyles.colors.tertiary}`,
        }
    },
};

export default withStyles(styles)(RouteFeatureGroup);