import React from 'react';
import {  withStyles } from '@material-ui/core';
import loopStyles from 'common/styles';
import { CompanyType } from 'common/constants';
import styled from 'styled-components';
import { Feature } from 'common/feature';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StatRow from './StatRow';
import YouTubeVideo from 'components/common/YouTubeVideo';

interface CompanyLayoutProps {
    feature: Feature,
    classes: any,
}

interface CompanyLayoutState {
    tabIndex: number,
    showVideo: boolean,
}

class CompanyLayout extends React.Component<CompanyLayoutProps, CompanyLayoutState> {
    state = {
        tabIndex: 0,
        showVideo: false,
    }

    render() {
        const { feature, classes } = this.props
        const { tabIndex } = this.state;

        return (
            <div style={{width: '400px'}}>
                <div style={{
                    backgroundSize: '100% 100%',
                    backgroundColor: '1px solid #434343',
                }}>
                    <div style={{ margin: '10px 18px' }}>
                        <SubtitleContainer>
                            <Icon src={`${process.env.PUBLIC_URL}/icons/companyIcons/${feature.featureID.split('_')[0]}.png`} alt={'img'} />
                            {
                                // We should check for companyType here, but can't currently do that without passing featureData down
                                `${feature.companyType === CompanyType.Loop ? 'Loop' : 'Hyperloop'} Company`
                            }
                        </SubtitleContainer>
                        <FeatureName>{feature.Name}</FeatureName>
                    </div>
                    {feature['YouTube Video'] && <div style={{ height: '225px' }}>
                        <YouTubeVideo link={feature['YouTube Video']} />
                    </div>}
                    <MediaIcons>
                        {/* <MediaIcon url="" icon={<TwitterIcon/>}/> */}
                    </MediaIcons> 
                </div>
                <Tabs
                    value={tabIndex}
                    onChange={this.handleChange}
                    className={classes.tabs}
                    TabIndicatorProps={{ style: { backgroundColor: 'rgba(0,0,0,0)' } }}
                >
                    <Tab className={classes.tab} label="Overview" />
                    <Tab className={classes.tab} label="Leadership" />
                    <Tab className={classes.tab} label="Finances" />
                </Tabs>
                {this.getTabContent(tabIndex)}
            </div>
        );
    }

    enableVideo = () => {
        this.setState({ showVideo: true })
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabIndex: newValue });
    }

    getTabContent = (tabIndex: number) => {
        const { feature } = this.props;

        switch (tabIndex) {
            case 0:
                return <>
                    <StatRow statLabel='Founded' value={feature.Founded} />
                    <StatRow statLabel='HQ' value={feature.HQ} />
                    <StatRow statLabel='Test Facility' value={feature['Test Facility']} />
                </>
            case 1:
                return <>
                    <StatRow statLabel='CEO' value={feature.CEO} />
                    <StatRow statLabel='President' value={feature.President} />
                    <StatRow statLabel='COO' value={feature.COO} />
                    <StatRow statLabel='CTO' value={feature.CTO} />
                </>
            case 2:
                return <>
                    <StatRow statLabel='Series A' value={feature['Series A']} />
                    <StatRow statLabel='Series B' value={feature['Series B']} />
                    <StatRow statLabel='Series B-1' value={feature['Series B-1']} />
                    <StatRow statLabel='Additional Funding' value={feature['Additional Funding']} />
                </>
        }
    }
}

const Icon = styled.img({
    marginRight: '10px',
})

// const StatLabel = styled.div({
//     fontFamily: 'Roboto',
//     fontStyle: 'normal',
//     fontWeight: 'bold',
//     fontSize: '13px',
//     letterSpacing: '0.1px',
//     marginBottom: '10px',
//     color: '#B7B7B7',
// })

// const PlayVideoText = styled.div({
//     fontFamily: 'Roboto',
//     fontStyle: 'normal',
//     fontWeight: 'bold',
//     fontSize: '14px',
//     lineHeight: '23px',
//     /* identical to box height, or 164% */
//     letterSpacing: '0.1px',
//     color: '#6FF3FC',
//     marginLeft: '300px',
//     cursor: 'pointer'
// })

// const StatValue = styled.div({
//     fontFamily: 'Poppins',
//     fontStyle: 'normal',
//     fontWeight: 'bold',
//     fontSize: '24px',
//     letterSpacing: '2px',
//     color: '#FFFFFF',
// })

const SubtitleContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '23px',
    letterSpacing: '0.1px',
    color: '#B7B7B7',
    opacity: 0.99,
})

const MediaIcons = styled.div({
    display: 'flex',
    position: 'absolute',
    paddingTop: '220px',
    paddingLeft: '48px',
})

const FeatureName = styled.div({
    width: 'auto',
    height: 'auto',
    overflow: 'ellipse',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '42px',
    letterSpacing: '0.10000000149011612px',
    color: '#FFFFFF',
    opacity: 0.99,
})

const styles = {
    tab: {
        width: '100px',
        minWidth: '100px',
        color: loopStyles.colors.secondary,
        '&:selected': {
            color: '#1890ff',
        },
        opacity: 0.3,    
    },
    tabs: {
        borderBottom: '1px solid #434343',
        '&$selected': {
            color: '#1890ff',
        },    
    },
    indicator: {
      backgroundColor: 'none',  
      color: '#1890ff',
    },
}

export default withStyles(styles)(CompanyLayout);