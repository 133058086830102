import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Feature, MapboxFeature, MapboxFeatureDictionary } from "common/feature";
import { getDocumentFromCollection } from "common/firestoreUtil";
import { getAllFeatures } from "common/functions";
import firebase from 'firebase/compat/app';

export const fetchMenuData = createAsyncThunk('menu/fetchMenuData', async () => {
    const firestoreDB = firebase.firestore();
    
    const document: Feature | undefined = await getDocumentFromCollection(firestoreDB, "menu", "menudata")
    return document;
});

const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        data: undefined
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenuData.pending, (state) => {
                // state.status = 'loading';
            })
            .addCase(fetchMenuData.fulfilled, (state, action) => {
                // state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchMenuData.rejected, (state, action) => {
                // state.status = 'failed';
                // state.error = action.error.message;
            });
    },
})

export default menuSlice.reducer;