const firebaseConfig = {
    apiKey: "AIzaSyDDNmN181-GWpd0s2Ujh-26HBMle89cThE",
    authDomain: "hypermap-1572818541462.firebaseapp.com",
    projectId: "hypermap-1572818541462",
    storageBucket: "hypermap-1572818541462.appspot.com",
    messagingSenderId: "141676650841",
    appId: "1:141676650841:web:0a3116e8c4c32f8db5ec48",
    measurementId: "G-9ZZNFE25QK"
};

export default firebaseConfig;