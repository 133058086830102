import { Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { drawerWidth } from './RouteSidebar/RouteSidebar';
import Button, { ButtonSize } from 'components/Button'
import Tab from './Tab';
import loopStyles from 'common/styles';
import Card from './Card/Card';
import AboutLayout from './Card/Layouts/AboutLayout';
import FAQLayout from './Card/Layouts/FAQLayout';
import Language from '@material-ui/icons/Language';
import { useDispatch, useSelector } from 'react-redux';
import AuthLayout from './Card/Layouts/FeatureLayout/AuthLayout';
import { AnimatePresence } from 'framer-motion';
import { logout } from 'store/reducers/user';
import { selectFeatureViaID } from 'store/reducers/map';

interface PageHeaderProps {
    showViewResetButton?: boolean,
    deselectAll?: any,
    classes?: any,
    startFirebaseLogin?: any,
    resetView?: () => void,
}

interface PageHeaderState {
    showAbout: boolean,
    showFAQ: boolean,
    showLogin: boolean,
}

const PageHeader = (props: PageHeaderProps) => {
    const dispatch = useDispatch()
    const user = useSelector((state: any) => state.user.user)
    const selectedFeatureData = useSelector((state: any) => state.map.selectedFeatureData)

    const [showAbout, setShowAbout] = useState(false)
    const [showFAQ, setShowFAQ] = useState(false)
    const [showLogin, setShowLogin] = useState(false)

    const { classes, showViewResetButton, deselectAll, resetView } = props;

    const loginOrLogout = () => {
        if (user) {
            dispatch(logout())
        } else {
            selectedFeatureData && dispatch(selectFeatureViaID())
        }
    }

    return <Container>
        <TitleContainer>
            <Typography variant="h5" className={classes.title}>
                HyperMap
            </Typography>
        </TitleContainer>
        <div style={{display: 'flex',  justifyContent: 'space-between', height: '100%', width: '100%', borderBottom: '2px solid #333233', zIndex: 4}}>
            <div style={{display: 'flex',  height: '100%', alignItems: 'center'}}>
                <Tab label={"About"} onClick={() => {
                    setShowAbout(!showAbout)
                    setShowFAQ(false)
                }} />
                <Tab label={"FAQ"} onClick={() => {
                    setShowAbout(false)
                    setShowFAQ(!showFAQ)
                }} />
                {/* <Tab label={"Contact Us"} onClick={() => {this.setState({
                showAbout: false,
                showFAQ: false,
                showContactUs: !this.state.showContactUs,
                })}} /> */}
                {/* <LoginTab onClick={this.toggleShowLogin}/> */}
            </div>
            <div onClick={loginOrLogout} style={{height: '100%', color: loopStyles.colors.secondary, display: 'flex', alignItems: 'center', fontSize: '14px', fontFamily: 'Poppins', margin: '0 20px', cursor: 'pointer', userSelect: 'none'}}>
                {user ? 'Log Out' : 'Log In'}
            </div>
        </div>
        {showAbout && <Card style={{top: '85px', left: `310px`}}>
            <AboutLayout />
        </Card>}
        {showFAQ && <Card style={{top: '85px', left: `310px`}}>
            <FAQLayout />
        </Card>}
    </Container>
}

const ResetButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 8px;
    &:hover {
        cursor: pointer;
        background-color: rgba(0,0,0,0.2);
    }
`

const TitleContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    userSelect: 'none',
    overflow: 'none', 
    minWidth: drawerWidth, 
    height: '100%'
})

const Container = styled.div({
    width: '100%',
    minHeight: '75px',
    display: 'flex'
})

const styles = {
    title: {
        color: loopStyles.colors.tertiary,
        fontFamily: 'OneDay',
        marginLeft: '30px'
    },
}

export default withStyles(styles)(PageHeader);