import loopStyles from 'common/styles';
import React from 'react';
import styled from 'styled-components';

type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError!) {
      // You can render any custom fallback UI
        return <ErrorBoundaryWrapper>
            Sorry, something went wrong.
            <RefreshLink href=".">Refresh</RefreshLink>
        </ErrorBoundaryWrapper>;
    }

    return this.props.children;
  }
}

const ErrorBoundaryWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    min-width: -webkit-fill-available;
    min-height: -webkit-fill-available;
    background-color: ${loopStyles.colors.primary};
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${loopStyles.colors.secondary}
`

const RefreshLink = styled.a`
    color: ${loopStyles.colors.tertiary}
    margin: 20px;
`

export default ErrorBoundary;
