export enum CSSPosition {
    Absolute = 'absolute'
}

export enum CSSTextAlign {
    Right = 'right'
}

export enum CSSUserSelect {
    None = 'none'
}

export enum ArticleSourceType {
    YouTube = 'YouTube',
    Twitter = 'Twitter',
    Article = 'Article',
}
export enum PageType {
    Map = 'map',
    Stat = 'stats',
    Teams = 'teams',
    IndustryUpdates = 'industryUpdates',
    IPTracker = 'IPtracker'
}

export enum CompanyType {
    Hyperloop = 'hyperloop',
    Loop = 'loop',
}

export enum FeatureType {
    View = 'view',
    Route = 'route',
    Point = 'point',
    Team = 'academicTeam',
    Company = 'company'
}

export const defaultMapSettings = {
    style: 'mapbox://styles/loopguys/cjzdk90ko36qw1cp760kxlwuf',
    center: [-23.45, 27.63] as [number, number],
    zoom: 1.66,
    minZoom: 1.51,
    zIndex: 0,
    dragRotate: false,
    // touchZoomRotate: false
}

export const messages = {
    Disclaimer: ' Routes displayed are for informational purposes only, exact locations should not be considered final and are subject to change.',
    WebsiteLink: ' home ',
}