import React from 'react';
import styled from "styled-components";
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import loopStyles from 'common/styles';
import { CSSPosition } from 'common/constants';

interface MessageModalProps {
    label: string,
    callback: any,
    classes: any,
}

function MessageModal(props: MessageModalProps) {
    return (
        <ModalStyle onClick={props.callback}>
            <div style={{margin: '0 50px 0 10px'}}>{props.label}</div>
            <IconButton className={props.classes.clearIcon}>
                <ClearIcon />
            </IconButton>
        </ModalStyle>
    );
}

const ModalStyle = styled.div({
    display: 'flex',
    flex: 0,
    cursor: 'pointer',
    position: 'absolute',
    padding: '5px',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
    color: loopStyles.colors.secondary,
    backgroundColor: '#181818',
    opacity: 0.8,
    border: `1px solid #312F32`,
    'box-sizing': 'border-box',
    borderRadius: '8px',
    zIndex: 4,
    'font-family': 'Poppins',
    'font-style': 'normal',
    'font-weight': 'normal',
    'font-size': '14px',
    userSelect: 'none',
    // minWidth: '60%',
    height: '60px',
})

const styles = {
    root: {
        display: 'flex',
    },
    clearIcon: {
        position: CSSPosition.Absolute,
        right: '10px',
        color: 'white',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
}

export default withStyles(styles)(MessageModal);