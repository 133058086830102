import { FeatureType } from "./constants";

export const filterViewFeatures = (feature: any): any => {
    return feature.featureType === FeatureType.View;
}

export const parseTime = (time: string) => {
    const year = time.slice(0, 4)
    const month = time.slice(5, 7)
    const day = time.slice(8, 10)

    return (`${month}/${day}/${year}`)
}

export const mapFirestoreObjectToArray = (firestoreObject:  any) =>  {
    return firestoreObject && Object.values(firestoreObject)
}

export const preventEventDefault = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.preventDefault();
}

export const convertNumToIDNum = (x: string | Number) => Number(x) > 9 ? x : `0${x}`