import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import loopStyles from 'common/styles';
import styled from 'styled-components';
import { Feature } from 'common/feature';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import YouTubeVideo from 'components/common/YouTubeVideo';

// import { ZoomInIcon } from '@material-ui/icons/ZoomIn';
import { ZoomIn as ZoomInIcon, Close as CloseIcon, CenterFocusWeak as CenterFocusWeakIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeatureViaID, updateSegmentRouteState, updateSelectedSectionIndex } from 'store/reducers/map';
import { AnimatePresence, motion } from 'framer-motion';

interface RouteLayoutProps {
    feature: Feature,
    classes: any,
}

const RouteLayout = (props: RouteLayoutProps) => {
    // Redux state
    const dispatch = useDispatch();
    const segmentRouteState = useSelector((state: any) => state.map.segmentRouteState)
    const menuData = useSelector((state: any) => state.menu.data)

    // Local State
    const [tabIndex, setTabIndex] = useState(0)
    const [isImperialUnits, setIsImperialUnits] = useState(true)

    const { feature, classes } = props;

    useEffect(() => {
        setTabIndex(0)
    }, [props.feature])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue)
    }

    const getStatusText = () => {
        switch (feature.status) {
            case 'Operational':
                return 'Route Operational'
            case 'Proposal':
                return 'Proposal Submitted'
            case 'Approval':
                return 'Awaiting Approval'
            case 'Construction':
                return 'Under Construction'
            default:
                return feature.status
        }
    }

    const getTabContent = (tabIndex: number) => {
        const { feature } = props;

        const distanceKey = isImperialUnits ? 'distanceMI' : 'distanceKM'
        const avgSpeedKey = isImperialUnits ? 'avgSpeedMPH' : 'avgSpeedKMH'
        const topSpeedKey = isImperialUnits ? 'topSpeedMPH' : 'topSpeedKMH'

        const distance = feature[distanceKey] !== "" ? `${feature[distanceKey]} ${isImperialUnits ? 'mi' : 'km'}` : '—'
        const avgSpeed = feature[avgSpeedKey] !== "" ? `${feature[avgSpeedKey]} ${isImperialUnits ? 'mph' : 'kmh'}` : '—'
        const travelTime = feature.travelTime !== "" ? `${feature.travelTime} ` : '—'
        const topSpeed = feature[topSpeedKey] !== "" ? `${feature[topSpeedKey]} ${isImperialUnits ? 'mph' : 'kmh'}` : '—'

        const isStatsEmpty = [distance, avgSpeed, travelTime, topSpeed].every(detail => detail === '—')
        const isDetailsEmpty = isStatsEmpty && feature.description === ''

        let content = undefined

        switch (tabIndex) {
            case 0:
                content = <DetailsContainer>
                    {!isDetailsEmpty ? <>
                        {!isStatsEmpty && <div style={{display: 'flex', width: '100%', textAlign: 'center', color: '#FFFFFF', margin: '20px 0'}}>
                            <div style={{width: '25%', borderRight: '1px solid #B7B7B7'}}>
                                <div>Distance</div>
                                <div style={{fontSize: '14px'}}>{distance}</div>
                            </div>
                            <div style={{width: '25%', borderRight: '1px solid #B7B7B7'}}>
                                <div>Top Speed</div>
                                <div style={{fontSize: '14px'}}>{topSpeed}</div>
                            </div>
                            <div style={{width: '25%', borderRight: '1px solid #B7B7B7'}}>
                                <div>Avg Speed</div>
                                <div style={{fontSize: '14px'}}>{avgSpeed}</div>
                            </div>
                            <div style={{width: '25%'}}>
                                <div>Time</div>
                                <div style={{fontSize: '14px'}}>{travelTime}</div>
                            </div>
                        </div>}
                        {feature.description && <div style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '23px',
                            letterSpacing: '0.1px',
                            color: '#B7B7B7',
                            padding: '10px 20px',
                        }}>
                            {feature.description}
                        </div>}
                    </> : <EmptyDetailsLabel>
                        Details for this route aren't currently available.
                    </EmptyDetailsLabel>}
                </DetailsContainer>
                break;
            case 1:
                content = feature?.segmentStructure && segmentRouteState && <OptionsContainer>
                    {feature.segmentStructure.map((sectionLength: any, sectionIndex: number) => {
                        const segmentFeature = feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]]

                        const distance = isImperialUnits ? `${segmentFeature.distanceMI === '' ? '—' : segmentFeature.distanceMI} mi` : `${segmentFeature.distanceKM === '' ? '—' : segmentFeature.distanceKM} km`
                        const topSpeed = isImperialUnits ? `${segmentFeature.topSpeedMPH === '' ? '—' : segmentFeature.topSpeedMPH} mph` : `${segmentFeature.topSpeedKMH === '' ? '—' : segmentFeature.topSpeedKMH} kmh`
                        const avgSpeed = isImperialUnits ? `${segmentFeature.avgSpeedMPH === '' ? '—' : segmentFeature.avgSpeedMPH} mph` : `${segmentFeature.avgSpeedKMH === '' ? '—' : segmentFeature.avgSpeedKMH} kmh`
                        const travelTime = segmentFeature.travelTime === '' ? '— min' : segmentFeature.travelTime
                        const isOptionDataEmpty = [distance, topSpeed, avgSpeed, travelTime].every(unit => unit.includes('—'))

                        return <div key={`segment:${sectionIndex}`} style={{ width: '100%', maxWidth: '100%', borderTop: sectionIndex > 0 ? `1px solid ${loopStyles.colors.secondary}` : '', marginTop: sectionIndex > 0 ? '20px' : '', paddingTop: sectionIndex > 0 ? '10px' : '' }}>
                            <div style={{
                                fontFamily: 'Poppins',
                                marginBottom: '8px',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                fontSize: '18px',
                                lineHeight: '18px',
                                letterSpacing: '0.1px',
                                color: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textOverflow: 'ellipsis'
                            }}>
                                {feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]].name}
                                <ZoomButton onClick={() => { dispatch(updateSelectedSectionIndex(sectionIndex)) }}>
                                    <CenterFocusWeakIcon style={{ marginRight: '4px' }} />
                                </ZoomButton>
                                <ZoomButton onClick={() => { dispatch(updateSelectedSectionIndex(sectionIndex)) }}>
                                    <ZoomInIcon style={{ marginRight: '4px' }} />
                                </ZoomButton>
                            </div>
                            {sectionLength > 1 && <div style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                margin: '20px 0',
                            }}>
                                {(new Array(sectionLength).fill(0)).map((option, optionIndex) => 
                                    <OptionBubble 
                                        selected={segmentRouteState && optionIndex === segmentRouteState[sectionIndex]} 
                                        onClick={() => {dispatch(updateSegmentRouteState({sectionIndex, optionIndex}))}}
                                    >
                                </OptionBubble>)}
                            </div>}
                            {!isOptionDataEmpty && <div style={{display: 'flex', width: '100%', textAlign: 'center', color: '#FFFFFF', margin: '20px 0'}}>
                                <div style={{width: '25%', borderRight: '1px solid #B7B7B7'}}>
                                    <div>Distance</div>
                                    <div style={{fontSize: '14px'}}>{distance}</div>
                                </div>
                                <div style={{width: '25%', borderRight: '1px solid #B7B7B7'}}>
                                    <div>Top Speed</div>
                                    <div style={{fontSize: '14px'}}>{topSpeed}</div>
                                </div>
                                <div style={{width: '25%', borderRight: '1px solid #B7B7B7'}}>
                                    <div>Avg Speed</div>
                                    <div style={{fontSize: '14px'}}>{avgSpeed}</div>
                                </div>
                                <div style={{width: '25%'}}>
                                    <div>Time</div>
                                    <div style={{fontSize: '14px'}}>{travelTime}</div>
                                </div>
                            </div>}
                            <div style={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '14px',
                                lineHeight: '23px',
                                letterSpacing: '0.1px',
                                color: '#B7B7B7',
                            }}>
                                {feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]].description}
                            </div>
                        </div>}
                    )}
                </OptionsContainer>
                break;
        }

        return content
    }

    // 85 for top padding, 15 for bottom
    return <div style={{ maxHeight: `${window.innerHeight - (85 + 20)}px`, display: 'flex', flexDirection: 'column'}}>
        <div style={{
            backgroundSize: '100% 100%',
            backgroundColor: '1px solid #434343',
            width: '100%',
        }}>
            <div style={{ margin: '0 20px'}}>
                <SubtitleContainer style={{justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <IconContainer>
                            <img src={`${process.env.PUBLIC_URL}/icons/companyIcons/${feature.featureID.split('_')[0]}.png`} alt={'img'} />
                        </IconContainer>
                        <div style={{fontFamily: 'Poppins'}}>{
                            // We should check for companyType here, but can't currently do that without passing featureData down
                            `${menuData[feature.companyID].name} - ${feature.companyID === 'BOR' ? 'Loop' : 'Hyperloop'} Route`
                        }</div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <IconWrapper onClick={() => { dispatch(updateSelectedSectionIndex(undefined)) }}>
                            <ZoomInIcon/>
                        </IconWrapper>
                        <IconWrapper onClick={() => { dispatch(selectFeatureViaID()) }}>
                            <CloseIcon/>
                        </IconWrapper>
                    </div>
                </SubtitleContainer>
                <div style={{marginBottom: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                    <FeatureName style={{borderBottom: `1px solid ${loopStyles[feature.companyID].colors.primary}`}}>{feature.name}</FeatureName>
                    <div style={{ color: '#B7B7B7' }}>{getStatusText()}</div>
                </div>
            </div>
            {feature.youtube && <div style={{ height: '225px' }}>
                <YouTubeVideo link={feature.youtube} />
            </div>}
        </div>  
        <Tabs
            value={tabIndex}
            onChange={handleChange}
            className={classes.tabs}
            TabIndicatorProps={{ style: { backgroundColor: loopStyles.colors.tertiary } }}
        >
            <Tab selected={tabIndex === 0} className={classes.tab} label="Details" />
            {feature.isSegmentRoute && <Tab selected={tabIndex === 1} className={classes.tab} label="Options" />}
            <div style={{display: 'flex', width: '100%', justifyContent: 'end',  alignItems: 'center'}}>
                <div style={{width: '100px', padding: '0 10px',}}>
                    <Toggle>
                        <ToggleItem isSelected={isImperialUnits} onClick={() => {setIsImperialUnits(true)}}>US</ToggleItem>
                        <ToggleItem isSelected={!isImperialUnits} onClick={() => {setIsImperialUnits(false)}}>SI</ToggleItem>
                    </Toggle>
                </div>
            </div>
        </Tabs>
        <div style={{overflowY: 'scroll'}}>
            {getTabContent(tabIndex)}
        </div>
    </div>
}

const EmptyDetailsLabel = styled.div`
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1px;
    margin-bottom: 10px;
    color: #B7B7B7;
`

const Toggle = styled.div`
    display: flex;
    height: 30px;
    border-radius: 15px;
    border: 1px solid ${loopStyles.colors.tertiary};
    color: ${loopStyles.colors.tertiary};
    width: 100%;
    margin: 8px 0;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
`

type ToggleItemProps = {
    isSelected: boolean
}

const ToggleItem = styled.div<ToggleItemProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    ${props => props.isSelected &&  `
    color: black;
    ${loopStyles.transition.animationStyles}
    background-color: ${loopStyles.colors.tertiary};
    `}
    :not(:last-child) {
        border-right: 1px solid ${loopStyles.colors.tertiary};
    }
`

const DetailsContainer = styled.div`
    width: 400px;
    padding: 10px 0;
    // min-height: 260px;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

const OptionsContainer = styled.div`
    max-width: 360px;
    width: 360px;
    min-height: 240px;
    height: 100%;
    overflow-y: scroll;
    padding: 0 20px;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

type OptionBubbleProps = {
    selected: boolean
}

const OptionBubble = styled.div<OptionBubbleProps>`
    width: 20px;
    height: 20px;
    border: 1px solid ${loopStyles.colors.tertiary};
    border-radius: 50%;
    background-color: ${props => props.selected ? loopStyles.colors.tertiary : loopStyles.colors.primary};
    color: ${props => props.selected ? loopStyles.colors.primary : loopStyles.colors.secondary};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    ${loopStyles.transition.animationStyles}
    &:hover {
       color: ${loopStyles.colors.primary}; 
       background-color: ${loopStyles.colors.tertiary};
    }
    
`

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 10px;
    height: 32px;
    width: 32px;
`

const ZoomButton = styled.div`
    display: flex;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    align-items: center;
    padding: 6px 8px;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    user-select: none;
    white-space: nowrap;
    &:hover {
        background-color: rgba(0,0,0,0.3);
    }
    ${loopStyles.transition.animationStyles}
`

const IconWrapper = styled.div`
    display: flex;
    border-radius: 8px;
    color: #ffffff;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    user-select: none;
    &:hover {
        background-color: rgba(0,0,0,0.3);
    }
    ${loopStyles.transition.animationStyles}
`

const StatLabel = styled.div({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '13px',
    letterSpacing: '0.1px',
    marginBottom: '10px',
    color: '#B7B7B7',
})

// const PlayVideoText = styled.div({
//     fontFamily: 'Roboto',
//     fontStyle: 'normal',
//     fontWeight: 'bold',
//     fontSize: '14px',
//     lineHeight: '23px',
//     /* identical to box height, or 164% */
//     letterSpacing: '0.1px',
//     color: '#6FF3FC',
//     marginLeft: '300px',
//     cursor: 'pointer'
// })

const StatValue = styled.div<{unit?: string}>({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        letterSpacing: '2px',
        color: '#FFFFFF',
    },
    ({ unit }) => ({
        "&:after": {
            position: 'absolute',
            height: '35px',
            content: `${unit ? `"${unit}"` : ""}`,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '23px',
            letterSpacing: '0.1px',
            color: '#B7B7B7',
            marginLeft: '4px',
            marginTop: '7px',
        },
    })
)

const SubtitleContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0',
    height: '32px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '23px',
    letterSpacing: '0.1px',
    color: '#B7B7B7',
    opacity: 0.99,
})

const FeatureName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: min-content;
    max-width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
`

const styles = {
    tab: {
        width: '100px',
        minWidth: '100px',
        borderRadius: '10px 10px 0 0',
        borderLeft: `1px solid #434343`,
        borderRight: `1px solid #434343`,
        borderTop: `1px solid #434343`,
        color: loopStyles.colors.secondary,
        margin: '0 2px',
        transition: 'all 0.2s ease-in',
        '&.Mui-selected, &:hover': {
            borderLeft: `1px solid ${loopStyles.colors.secondary}`,
            borderRight: `1px solid ${loopStyles.colors.secondary}`,
            borderTop: `1px solid ${loopStyles.colors.secondary}`,
        },
    },
    tabs: {
        marginTop: '8px',
        borderBottom: '1px solid #434343',
        color: 'white',
        '&:selected': {
            color: '#1260cc',
        },
    },
    indicator: {
      backgroundColor: 'none',  
      color: '#1890ff',
    },
}

export default withStyles(styles)(RouteLayout);