import { combineReducers } from "@reduxjs/toolkit";
import mapReducer from './map'
import userReducer from './user'
import globalReducer from './global'
import filtersReducer from './filters'
import menuReducer from './menu'

const rootReducer = combineReducers({
    map: mapReducer,
    global: globalReducer,
    filters: filtersReducer,
    menu: menuReducer,
    user: userReducer,
})

export default rootReducer;
