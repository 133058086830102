import React from 'react';
import loopStyles from 'common/styles';
import { FeatureType } from 'common/constants';
import styled from 'styled-components';
import { Feature } from 'common/feature';
import { ReactComponent as TeamIcon } from 'common/svgIcons/TeamIcon.svg';
import TeamLayout from './TeamLayout';
import RouteLayout from './RouteLayout';
import CompanyLayout from './CompanyLayout';
import PointLayout from './PointLayout';

import AuthLayout from './AuthLayout';
import { useSelector } from 'react-redux';

interface FeatureLayoutProps {
    feature: Feature,
}

const FeatureLayout = (props: FeatureLayoutProps) => {
    const user = useSelector((state: any) => state.user.user)

    const isUserAuthenticatedAndVerified = user?.emailVerified

    const getLayout = () => {
        const { feature } = props;

        switch (feature?.featureType) {
            case FeatureType.Team:
                return <TeamLayout feature={feature}/>
            case FeatureType.Route:
                return <RouteLayout feature={feature}/>
            case FeatureType.Company:
                return <CompanyLayout feature={feature}/>
            case FeatureType.Point:
                return <PointLayout feature={feature}/>
        }

        if(feature?.featureType === 'office'){
            return <PointLayout feature={feature}/>
        }
        if(feature?.entityType === 'Company'){
            return <CompanyLayout feature={feature}/>
        }
    }

    const getIcon = () => {
        const { feature } = props;

        switch (feature?.featureType || feature?.entityType) {
            case FeatureType.Team:
                return <TeamIcon fill={loopStyles.colors.secondary}/>
            case FeatureType.Route:
                return <img src={`${process.env.PUBLIC_URL}/icons/companyIcons/${feature?.companyID}.png`} alt={'img'} />
        }
    }

    return (
        <Container>
            {isUserAuthenticatedAndVerified ? getLayout() : <AuthLayout/>}
        </Container>
    );
}

const Container = styled.div({
    // width: '517px',
    // height: '160px',
})

export default FeatureLayout;