import React from 'react';
import styled from 'styled-components';

interface StatRowProps {
    statLabel: string,
    value: string,
}

class StatRow extends React.Component<StatRowProps> {
    render() {
        const { statLabel, value } = this.props;

        return (value !== undefined &&
            <Container>
                <StatLabel>{statLabel}</StatLabel>
                <Value>{value}</Value>
            </Container>
        );
    }
}

const StatLabel = styled.div({
    marginLeft: '20px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '23px',
    letterSpacing: '0.1px',
    color: '#B7B7B7',
    opacity: 0.99,
})

const Value = styled.div({
    margin: '8px 20px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '22px',
    textAlign: 'right',
    letterSpacing: '0.1px',
    color: '#FFFFFF',
    overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    display: 'block',
})

const Container = styled.div({
    width: '100%',
    maxWidth: '100%',
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #454545',
})

export default StatRow;