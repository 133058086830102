import styled from 'styled-components';
import { motion } from 'framer-motion';

const Card = styled(motion.div)`
    position: absolute;
    background: #343436;
    opacity: 0.99;
    border-radius: 10px;
    min-width: 100px;
    z-index: 5;
    border: 1px solid rgba(0, 0, 0, .25);
`

export default Card