import loopStyles from "common/styles";
import Sidebar from "components/common/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import TuneIcon from '@mui/icons-material/Tune';
import ExpandLess from '@mui/icons-material/ExpandLess';
import SidebarGroup from "./RouteSidebarGroup";
import { useState } from "react";
import Language from '@material-ui/icons/Language';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { motion } from "framer-motion";
import TeamSidebarGroup from "./TeamSidebarGroup";
import { updateFilter } from "store/reducers/filters";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

type RouteSidebarProps = {
    toggleSidebar: () => void,
    isOpen: boolean,
    resetView: () => void,
}

const statuses = [
    'Concept',
    'Proposal',
    'Approval',
    'Construction',
    'Operational',
    'On Hold',
    'Canceled',
]

const RouteSidebar = (props: RouteSidebarProps) => {
    const dispatch = useDispatch()

    const isMobile = useSelector((state: any) => state.global.isMobile)
    const menuData = useSelector((state: any) => state.menu.data)
    const filters = useSelector((state: any) => state.filters)

    const [isFiltersOpen, setIsFiltersOpen] = useState(false)
    const [isHyperloopsOpen, setIsHyperloopsOpen] = useState(true)
    const [isLoopsOpen, setIsLoopsOpen] = useState(true)
    const [isTeamsOpen, setIsTeamsOpen] = useState(true)

    const filterCompany = (company: any) => {
        if (filters.company.length > 0 && (!filters.company.includes(company.companyID) && !filters.company.includes(company.featureID))) {
            return false
        }

        if (company.points?.length === 0 && company.routes?.length === 0) {
            return false
        }

        return true
    }

    const generateFilterChangeHandler = (key: string) => {
        return (ev: any) => {
            const copyOfFilter = new Set(filters[key])
            copyOfFilter.has(ev.currentTarget.id) ? copyOfFilter.delete(ev.currentTarget.id) : copyOfFilter.add(ev.currentTarget.id)
            console.log(key, copyOfFilter)
            dispatch(updateFilter({key, filter: Array.from(copyOfFilter)}))
        }
    }

    const { toggleSidebar, isOpen, resetView } = props;

    return <Sidebar fullscreen={isMobile} onClose={toggleSidebar} isOpen={!isMobile || isOpen}>
        <div style={{borderRight: '2px solid rgb(51, 50, 51)', height: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', padding: '20px 0'}}>
                <IconButton onClick={resetView}>
                    <StyledLanguageIcon/>
                    <FilterButtonLabel>Reset View</FilterButtonLabel>
                </IconButton>
                <IconButton onClick={() => { setIsFiltersOpen(!isFiltersOpen) }}>
                    <StyledTuneIcon/>
                    <FilterButtonLabel>Filters</FilterButtonLabel>
                </IconButton>
            </div>
            <Scrollable>
                {isFiltersOpen ? <div style={{padding: '0 20px'}}>
                    <div style={{color: loopStyles.colors.secondary, borderBottom: `1px solid ${loopStyles.colors.tertiary}`, paddingBottom: '2px', marginBottom: '10px'}}>Companies</div>
                    {filters && Object.keys(menuData!).filter(key => menuData[key].name).sort((a: any, b: any) => Number(menuData[a].menuSortOrder) - Number(menuData[b].menuSortOrder)).map(key => <FilterRow key={`filter-${key}-${filters.company.includes(key)}`} onClick={generateFilterChangeHandler('company')} id={key}>
                        <ListItemIconContainer color={loopStyles[key]?.colors.primary}>
                            {filters.company.includes(key) ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                        </ListItemIconContainer>
                        <CheckboxLabel>{menuData[key].name}</CheckboxLabel>
                    </FilterRow>)}
                    <div style={{color: loopStyles.colors.secondary, borderBottom: `1px solid ${loopStyles.colors.tertiary}`, paddingBottom: '2px', marginBottom: '10px'}}>Route Status</div>
                    {statuses.map(status => {
                        return <FilterRow onClick={generateFilterChangeHandler('status')} id={status} key={`filter-${status}`}>
                            <ListItemIconContainer color={loopStyles.colors.tertiary}>
                                {filters.status.includes(status) ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                            </ListItemIconContainer>
                            <CheckboxLabel >{status}</CheckboxLabel>
                        </FilterRow>
                    })}
                    <div style={{color: loopStyles.colors.secondary, borderBottom: `1px solid ${loopStyles.colors.tertiary}`, paddingBottom: '2px', marginBottom: '10px'}}>Teams</div>
                    <FilterRow onClick={() => { dispatch(updateFilter({key: 'teams', filter: !filters.teams })) }} id='teams'>
                        <ListItemIconContainer color={loopStyles.colors.tertiary}>
                            {filters.teams ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                        </ListItemIconContainer>
                        <CheckboxLabel >Hide teams</CheckboxLabel>
                    </FilterRow>
                </div> : <div>
                    <GroupTitle>
                        <span>Hyperloop Companies</span>
                        <CollapseButton onClick={() => { setIsHyperloopsOpen(!isHyperloopsOpen) }}>
                            {isHyperloopsOpen ? <StyledRemoveIcon/> : <StyledAddIcon/>}
                        </CollapseButton>
                    </GroupTitle>
                    {menuData && isHyperloopsOpen && <motion.div 
                        initial={{ height: 0, opacity: 0 }}
                        exit={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                    >
                         {Object.values(menuData!).filter(filterCompany).filter((company: any) => company?.techType === 'HyperLoop').sort((a: any,b: any) => Number(a.menuSortOrder) - Number(b.menuSortOrder)).map((company: any) =>  <SidebarGroup company={company}/>)}
                    </motion.div>}
                    <GroupTitle>
                        <span>Loop Companies</span>
                        <CollapseButton onClick={() => { setIsLoopsOpen(!isLoopsOpen) }}>
                            {isLoopsOpen ? <StyledRemoveIcon/> : <StyledAddIcon/>}
                        </CollapseButton>
                    </GroupTitle>
                    {menuData && isLoopsOpen && <motion.div 
                        initial={{ height: 0, opacity: 0 }}
                        exit={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                    >
                        {Object.values(menuData!).filter(filterCompany).filter((company: any) => company?.techType === 'Loop').sort((a: any,b: any) => Number(a.menuSortOrder) - Number(b.menuSortOrder)).map((company: any) =>  <SidebarGroup company={company}/>)}
                    </motion.div>}
                    <GroupTitle>
                        <span>Academic Teams</span>
                        <CollapseButton onClick={() => { setIsTeamsOpen(!isTeamsOpen) }}>
                            {isTeamsOpen ? <StyledRemoveIcon/> : <StyledAddIcon/>}
                        </CollapseButton>
                    </GroupTitle>
                    {menuData?.teams && isTeamsOpen && <motion.div 
                        initial={{ height: 0, opacity: 0 }}
                        exit={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                    >
                        {Object.keys(menuData?.teams).map((area: string) => <TeamSidebarGroup name={area} teams={menuData?.teams[area]}/>)}
                    </motion.div>}
                </div>}
            </Scrollable>
        </div>
    </Sidebar>
}

const FilterRow = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    ${loopStyles.transition.animationStyles}
    &:hover {
        background-color: rgba(255,255,255,0.1);
    }
`

const CheckboxLabel = styled.div`
    color: ${loopStyles.colors.secondary};
    flex: 1;
    // user-select: none;
`

const Checkbox = styled.input`
    accent-color: ${loopStyles.colors.tertiary};
    width: 16px;
    height: 16px;
    margin-right: 8px;
    transition: all 0.2s ease-in;
`

const StyledRemoveIcon = styled(RemoveIcon)`
    width: 20px;
    height: 20px;
`

const StyledAddIcon = styled(AddIcon)`
    width: 20px;
    height: 20px;
`

const CollapseButton = styled.div`
    border-radius: 50%;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    transition: all 0.2s ease-in;
    &:hover {
        background-color: ${loopStyles.colors.tertiary};
        color: ${loopStyles.colors.primary};
    }
`

type IconButtonProps = {
    selected?: boolean
}

const IconButton = styled.div<IconButtonProps>`
    width: fit-content;
    display: flex;
    align-items: center;
    font-family: Poppins;
    white-space: nowrap;
    color: #B5B5BE;
    padding: 5px 10px;
    margin: 0 5px;
    border-radius: 8px;
    ${loopStyles.transition.animationStyles}
    ${props => props.selected && 'background-color: rgba(0,0,0,0.2);'}
    &:hover {
        cursor: pointer;
        color: white;
        background-color: rgba(0,0,0,0.2);
    }
`

const Scrollable = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1;
    ${loopStyles.scrollbar.default}
`

type SelectedProps = {
    selected: boolean,
}

const FilterTab = styled.div<SelectedProps>`
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #B5B5BE;
    cursor: pointer;
    margin: 0 8px 8px;
    transition: all 0.2s ease-in;
    border: 1px solid ${loopStyles.colors.tertiary};
    opacity: ${props => props.selected ? '1 !important' : 0.3};
    &:hover {
        opacity: 0.6;
    }
`

const SidebarGroupHeader = styled.div`
    color: #B5B5BE;
    max-width: 100%;
    height: 40px;
    font-family: Poppins;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    ${loopStyles.transition.animationStyles}
    &:hover {
        * { 
            // color: ${loopStyles.colors.tertiary};
            ${loopStyles.transition.animationStyles}
        }
        background-color: rgba(0,0,0,0.1);
    }
`

type CompanyFilterButtonProps = {
    color: string,
    selected: boolean,
}

const CompanyFilterButton = styled.button<CompanyFilterButtonProps>`
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    border: 1px solid ${props => props.color};
    cursor: pointer;
    background-color: ${props => props.selected ? props.color : 'transparent'};
    border-radius: 50%;
    ${loopStyles.transition.animationStyles}
    &:hover {
        border: 3px solid ${props => props.color};
    }
`

const FilterButtonLabel = styled.div`
`

const StyledLanguageIcon = styled(Language)`
    margin-right: 10px;
    border-radius: 50%;
    ${loopStyles.transition.animationStyles}
`

const StyledTuneIcon = styled(TuneIcon)`
    margin-right: 10px;
    border-radius: 50%;
    ${loopStyles.transition.animationStyles}
`

const GroupTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px 10px;
    color: ${loopStyles.colors.tertiary};
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    :not(:first-child) {
        margin-top: 10px;
    }
`

type ListItemIconContainerProps = {
    color?: string,
}

const ListItemIconContainer = styled.div<ListItemIconContainerProps>`
    color: ${props => props.color ? props.color : loopStyles.colors.tertiary};
    width: 36px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const FilterButton = styled.button`
    background-color: rgba(127,127,127,0.5);
    color: ${loopStyles.colors.secondary};
    font-size: 20px;
    padding: 0;
    font-family: Poppins;
    height: 40px;
    margin: 10px 20px;
    border: 1px solid transparent;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    ${loopStyles.transition.animationStyles}
    &:hover {
        background-color: rgba(127,127,127,0.7);
    }
`

export default RouteSidebar