import { MapboxFeature } from "common/feature"
import { dimLayer, getRouteIDfromPointFeature, loadLayers, showLayer } from "common/functions"
import { createMap } from "common/mapboxUtil"
import { convertNumToIDNum, mapFirestoreObjectToArray } from "common/utils"
import mapboxgl from "mapbox-gl"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchMapboxFeatures, updateSelectedSectionIndex, zoomToSelection } from "store/reducers/map"
import styled from "styled-components"

type MapProps = {
    mapboxMap: mapboxgl.Map | null,
    updateMapboxMap: (map: mapboxgl.Map) => void;
}

const Map = (props: MapProps) => {

    const dispatch = useDispatch()

    const segmentRouteState = useSelector((state: any) => state.map.segmentRouteState)
    const mapboxFeatures = useSelector((state: any) => state.map.mapboxFeatures)
    const filters = useSelector((state: any) => state.filters)
    const selectedFeatureData = useSelector((state: any) => state.map.selectedFeatureData)
    const selectedSectionIndex = useSelector((state: any) => state.map.selectedSectionIndex)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const zoom = useSelector((state: any) => state.map.zoom)

    const [isMapStyleLoaded, setIsMapStyleLoaded] = useState(false)
    const [highlightedFeatureIDs, setHighlightedFeatureIDs] = useState<string[]>([])

    const mapRef = useRef(null)

    const onMapLoad = async (mapInstance: mapboxgl.Map) => {
        setIsMapStyleLoaded(true)
    }

    const createMapboxMap = async () => {
        const { updateMapboxMap } = props;

        let mapInstance: mapboxgl.Map = await createMap(mapRef.current);
        updateMapboxMap(mapInstance)

        mapInstance.on('load', async () => {
            onMapLoad(mapInstance);
        })

        mapInstance.on('idle', () => {
            mapInstance.resize()
        })
    }

    useEffect(() => {
        dispatch(fetchMapboxFeatures())
    }, [])

    useEffect(() => {
        createMapboxMap()
    },  [mapRef])

    useEffect(() => {
        const { mapboxMap } = props;

        if (isMapStyleLoaded && mapboxMap?.getLayer('allfeatures') === undefined) {
            mapboxFeatures && mapboxMap && loadLayers(mapboxMap, Object.values(mapboxFeatures))
        }
    }, [mapboxFeatures, isMapStyleLoaded])

    const zoomToRoute = (route: any) => {
        const { mapboxMap } = props

        if (!route) return;
    
        const coordinates = route.geometry.coordinates.reduce((accumulator: any, linestring: any) => [...accumulator, ...linestring], [])
        const bounds = new mapboxgl.LngLatBounds(
          coordinates[0],
          coordinates[0]
        );
           
        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinates) {
          bounds.extend(coord);
        }
           
        mapboxMap?.fitBounds(bounds, isMobile ? {} : {
          padding: { right: 440, top: 40, left: 40, bottom: 40 }
        });
    }

    const autoZoom = (featureID: any) => {
        const { mapboxMap } = props

        if (mapboxFeatures[featureID]?.geometry.type === 'Point') {
            mapboxMap?.setZoom(10.0)
            mapboxMap?.flyTo({ center: mapboxFeatures[featureID]?.geometry.coordinates })
        } else {
            selectedFeatureData?.isSegmentRoute === true ? mapboxMap?.fitBounds(
                mapFirestoreObjectToArray(selectedFeatureData.segmentZooms.route as any) as mapboxgl.LngLatBoundsLike, 
                {padding: isMobile ? undefined : { right: 440, top: 40, left: 40, bottom: 40 }}
            ) : zoomToRoute(mapboxFeatures[featureID]);
        }    
    }

    const getHighlightedPointFeatures = (highlightIDs: any[]) => {
        // map selected options to the station IDs associated with that option, then reduce to a 1D array of station IDs
        const activeSegmentStationIDs = segmentRouteState?.map((optionValue: number, sectionIndex: number) => selectedSectionIndex === undefined || selectedSectionIndex === sectionIndex ? selectedFeatureData?.segmentData[sectionIndex][optionValue].stationIDs : [])
            .reduce((prev: any[], current: any[]) => current?.length ? [...prev, ...current] : prev, [])
        // list of all mapbox point features
        const points = Object.values(mapboxFeatures).filter((feature: any) => feature.geometry.type === "Point")
        
        const filterHighlightedPoints = ((point: any) => {
            const isActiveSegmentStation = !!activeSegmentStationIDs?.length && activeSegmentStationIDs.indexOf(point.properties!.featureID) > -1
            const isHighlightedPoint = !selectedFeatureData?.isSegmentRoute && getRouteIDfromPointFeature(point) && highlightIDs.indexOf(getRouteIDfromPointFeature(point)) > -1
            const isHiddenTeam = point.properties!.companyID === 'AHT' && filters.teams

            return !isHiddenTeam && (isActiveSegmentStation || isHighlightedPoint)
        })

        return points.filter(filterHighlightedPoints)
    }

    const getHighlightedRouteFeatures = (highlightIDs: string[]) => {
        // map segment route state to the feature ID of each selected option
        const activeSegmentIDs = segmentRouteState?.map((optionValue: number, sectionIndex: number) => `${selectedFeatureData?.featureID}_s${convertNumToIDNum(sectionIndex + 1)}_${convertNumToIDNum(optionValue + 1)}`)
            .filter((segmentID: string, sectionIndex: number) => selectedSectionIndex === undefined || selectedSectionIndex === sectionIndex)

        const getParentID = (r: any) => r.properties?.featureID.split('_').slice(0, 2).join('_')
        const filterActiveOptionRoutes = (route: any) => {
            // if it's an option route, do not filter
            if (route.properties?.featureID.split('_').length !== 4) {
                return true;
            }

            if (route.properties.featureID === selectedFeatureData?.featureID) {
                return true;
            }

            // if the option is part of the currently selected route
            if (getParentID(route) === selectedFeatureData?.featureID) {
                return activeSegmentIDs.includes(route.properties.featureID)
            }

            const [sectionNumber, optionNumber] = route.properties?.featureID.split('_').slice(2).map((str: string) => Number(str))
            return optionNumber === 1
        }

        // array of all mapbox route features
        const routes = Object.values(mapboxFeatures).filter((feature: any) => feature.geometry.type !== "Point")

        const filteredCompanyRoutes = filters.company.length ? routes.filter((route: any) => filters.company.includes(route.properties.companyID)) : routes;
        const filteredStatusRoutes = filters.status.length ? filteredCompanyRoutes.filter((route: any) => filters.status.includes(route.properties.status)) : filteredCompanyRoutes;

        const highlightedRoutes = routes.filter((route: any) => highlightIDs.indexOf(getParentID(route)) > -1)

        const selectedRoutes = routes.filter((route: any) => getParentID(route) === selectedFeatureData?.featureID)
        const displayRoutes = [...(selectedRoutes.length ? selectedRoutes : filteredStatusRoutes), ...highlightedRoutes].filter(filterActiveOptionRoutes)
        return displayRoutes
    }

    useEffect(() => {
        if (selectedFeatureData) {
            autoZoom(selectedFeatureData.featureID)
        }
    }, [selectedFeatureData])

    // Update route display
    useEffect(() => {
        const { mapboxMap } = props;

        const map = mapboxMap as any

        if (!mapboxFeatures || !mapboxMap) return;

        const highlightIDs = [...highlightedFeatureIDs]
        selectedFeatureData && highlightIDs.push(selectedFeatureData.featureID)

        const displayPoints = getHighlightedPointFeatures(highlightIDs);
        const displayCities = (displayPoints as MapboxFeature[]).filter((feature: MapboxFeature) => feature.properties!.companyID !== 'AHT')
        const displayTeams = (displayPoints as MapboxFeature[]).filter((feature: MapboxFeature) => feature.properties!.companyID === 'AHT')
        const displayFeatures = getHighlightedRouteFeatures(highlightIDs);

        (map?.getSource('selectedfeatures'))?.setData({
            "type": "FeatureCollection",
            "features": displayFeatures
        })

        // map.resize();
        if (displayFeatures.length === 0 && displayPoints.length === 0 && filters.company.length === 0) {
            showLayer(map, 'allfeatures')
            showLayer(map, 'teams')

            map?.getSource('points').setData({
                type: "FeatureCollection",
                features: []
            })
            map?.getSource('citynames').setData({
                type: "FeatureCollection",
                features: []
            })
            map?.getSource('teams').setData({
                type: "FeatureCollection",
                features: filters.teams ? [] : (Object.values(mapboxFeatures) as MapboxFeature[]).filter((feature: MapboxFeature) => feature.properties!.companyID === 'AHT')
            })
            map?.getSource('selectedteams').setData({
                type: "FeatureCollection",
                features: []
            })
        } else {
            dimLayer(map, 'allfeatures');
            dimLayer(map, 'teams');

            map?.getSource('points')?.setData({
                "type": "FeatureCollection",
                "features": displayCities
            })
            map?.getSource('citynames')?.setData({
                "type": "FeatureCollection",
                "features": displayCities
            })
            map?.getSource('teams')?.setData({
                type: "FeatureCollection",
                features: filters.teams ? [] : (Object.values(mapboxFeatures) as MapboxFeature[]).filter((feature: MapboxFeature) => feature.properties!.companyID === 'AHT' && !displayTeams.some(team => team.properties!.featureID === feature.properties!.featureID))
            })
            map?.getSource('selectedteams')?.setData({
                type: "FeatureCollection",
                features: displayTeams,
            })
        }
    }, [selectedFeatureData, selectedSectionIndex, segmentRouteState, filters, highlightedFeatureIDs])

    useEffect(() => {
        const { mapboxMap } = props;

        if (zoom === true) {
            console.log(selectedSectionIndex, mapboxMap, selectedFeatureData);
            if (selectedSectionIndex !== undefined) {
                // mapboxMap?.fitBounds(
                //     mapFirestoreObjectToArray(selectedFeatureData.segmentZooms.route as any) as mapboxgl.LngLatBoundsLike, 
                //     {padding: isMobile ? undefined : { right: 440, top: 40, left: 40, bottom: 40 }}
                // );

                console.log(mapboxMap, mapFirestoreObjectToArray(selectedFeatureData.segmentZooms.sections[selectedSectionIndex]))
                mapboxMap?.fitBounds(
                    mapFirestoreObjectToArray(selectedFeatureData.segmentZooms.sections[selectedSectionIndex]), 
                    isMobile ? {} : {padding: { right: 440, top: 40, left: 40, bottom: 40 }}
                )
            } else if (mapboxMap && selectedFeatureData) {
                console.log('here??')
                autoZoom(selectedFeatureData.featureID)
            }
            //set zoom to false in Redux
            dispatch(zoomToSelection())
        }
    }, [zoom])
    
    return <MapContainer ref={mapRef} />
}

const MapContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    outline: none;
    position: relative;
`

export default Map