export const getCollection = async (firestoreDB: any, collectionName: string) => {
    let collectionData = {} as any;
    const collectionRef = firestoreDB?.collection(collectionName);

    await collectionRef.get().then((collection: any) => {
      collection.docs.forEach((doc: any) => {
        const data = doc.data();          
        collectionData[data.featureID] = data;
      })
    }).catch((error: any) => {
        console.log("Error getting document:", error);
    });

    return  collectionData;
}

export const getDocumentFromCollection = async (firestoreDB: any, collectionName: string, documentID: string | undefined) => {
    if (!documentID) return undefined;
    
    var docRef = firestoreDB.collection(collectionName).doc(documentID);

    let document;
    await docRef.get().then((doc: any) => {
        if (doc.exists) {
          document = doc.data()
        } else {
          console.error("Failed to retrieve document data.");
        }
    })
    .catch((error: any) => {
        console.error("Error getting document:", error);
    });
    return document;
}