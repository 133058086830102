import React from 'react';
import { withStyles } from '@material-ui/core';
import loopStyles from 'common/styles';
import styled from 'styled-components';

interface MediaIconProps {
    icon: any,
    url: string,
    classes: any,
}

interface MediaIconState {
    tabIndex: number,
}

class MediaIcon extends React.Component<MediaIconProps, MediaIconState> {
    state = {
        tabIndex: 0
    }

    render() {
        const { icon } = this.props

        return (
            <Container onClick={this.openLink}>
                {icon}
            </Container>
        );
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabIndex: newValue });
    }

    openLink = () => {
        const { url } = this.props;

        window.open(url, "_blank")
      }
}

const Container = styled.div`
    height: 38px;
    width: 38px;
    display: flex;
    margin-right: 4px;
    justify-content: center;
    align-items: center;
    background: #313134;
    border: 1px solid rgba(123, 123, 123, 0.49);
    box-sizing: border-box;
    border-radius: 94px;
    fill: #969696;
    color: #969696;
    cursor: pointer;
    ${loopStyles.transition.animationStyles}
    &:hover {
        fill: ${loopStyles.colors.tertiary};
        color: ${loopStyles.colors.tertiary};
        border: 1px solid ${loopStyles.colors.tertiary};
    }
`

const styles = {
    tab: {
        width: '100px',
        minWidth: '100px',
        color: loopStyles.colors.secondary,
        '&:selected': {
            color: '#1890ff',
        },    
    },
    tabs: {
        '&$selected': {
            color: '#1890ff',
        },    
    },
    indicator: {
      backgroundColor: 'none',  
      color: '#1890ff',
    },
}

export default withStyles(styles)(MediaIcon);