import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import loopStyles from 'common/styles'
import { CSSPosition } from 'common/constants';
import styled from 'styled-components';
import RouteFeatureGroup from 'components/Sidebar/layouts/RouteSidebarLayout/RouteFeatureGroup';
import { AnimatePresence, motion } from 'framer-motion';
import Tune from '@material-ui/icons/Tune';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilter } from 'store/reducers/filters';
import Language from '@material-ui/icons/Language';
import { fetchMenuData } from 'store/reducers/menu';
import TeamSidebarGroup from './TeamSidebarGroup';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const statuses = [
    'Concept',
    'Proposal',
    'Approval',
    'Construction',
    'Operational',
    'On Hold',
    'Canceled',
]

interface RouteSidebarLayoutProps {
    classes: any,
    firestoreDB?: any,
    resetView: () => void;
}

function RouteSidebarLayout(props: RouteSidebarLayoutProps) {
    const dispatch = useDispatch()
    const filters = useSelector((state: any) => state.filters)
    const menuData = useSelector((state: any) => state.menu.data)

    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
    const [isHyperloopsOpen, setIsHyperloopsOpen] = useState(true);
    const [isLoopsOpen, setIsLoopsOpen] = useState(true);
    const [isTeamsOpen, setIsTeamsOpen] = useState(true);

    const filterCompany = (company: any) => {
        if (filters.company.length > 0 && (!filters.company.includes(company.companyID) && !filters.company.includes(company.featureID))) {
            return false
        }

        return true
    }

    const generateFilterChangeHandler = (key: string) => {
        return (ev: any) => {
            const copyOfFilter = new Set(filters[key])
            copyOfFilter.has(ev.target.id) ? copyOfFilter.delete(ev.target.id) : copyOfFilter.add(ev.target.id)
            console.log(key, copyOfFilter)
            dispatch(updateFilter({key, filter: Array.from(copyOfFilter)}))
        }
    }

    useEffect(() => {
        dispatch(fetchMenuData())
    }, [dispatch])

    return <AnimatePresence exitBeforeEnter>
        {menuData && <motion.div style={{display: 'flex', flexDirection: 'column', maxHeight: '100%'}} animate={{opacity: 1}} initial={{opacity: 0}}>
            <div style={{ position: 'relative',}}>
                <div style={{width: '100%', display: 'flex', justifyContent:'center', margin: '20px 0'}}>
                    <IconButton onClick={props.resetView}>
                        <Language style={{color: loopStyles.colors.secondary, marginRight: '8px'}}/>
                        Reset View
                    </IconButton>
                    <IconButton selected={isFilterMenuOpen} onClick={() => { setIsFilterMenuOpen(!isFilterMenuOpen) }}>
                        <Tune style={{color: 'white', marginRight: '8px'}}/>
                        Filters
                    </IconButton>
                </div>
                {isFilterMenuOpen && <div style={{position: 'absolute', right: -10, top: 10 }}>
                    <motion.div style={{position:'fixed', zIndex: 10, width: '200px', backgroundColor: '#222223', border: `1px solid #333233`, borderRadius: '5px', padding: '15px 10px', maxHeight: '50vh', overflowY: 'auto'}} initial={{opacity: 0}} exit={{ opacity: 0}} animate={{opacity: 1}}>
                        <div style={{color: loopStyles.colors.secondary, borderBottom: `1px solid ${loopStyles.colors.tertiary}`, paddingBottom: '2px', marginBottom: '10px'}}>Companies</div>
                        {filters && Object.keys(menuData!).filter(key => menuData[key].name).sort((a: any, b: any) => Number(menuData[a].menuSortOrder) - Number(menuData[b].menuSortOrder)).map(key => <div key={`filter-${key}`} style={{display: 'flex', alignItems: 'center', margin: '10px 0'}}>
                            <Checkbox onChange={generateFilterChangeHandler('company')} checked={filters.company.includes(key)} id={key} type='checkbox'/>
                            <CheckboxLabel htmlFor={key}>{menuData[key].name}</CheckboxLabel>
                        </div>)}
                        <div style={{color: loopStyles.colors.secondary, borderBottom: `1px solid ${loopStyles.colors.tertiary}`, paddingBottom: '2px', marginBottom: '10px'}}>Route Status</div>
                        {statuses.map(status => {
                            return <div key={`filter-${status}`} style={{display: 'flex', alignItems: 'center', margin: '10px 0'}}>
                                <Checkbox onChange={generateFilterChangeHandler('status')} checked={filters.status.includes(status)} id={status} type='checkbox'/>
                                <CheckboxLabel htmlFor={status}>{status}</CheckboxLabel>
                            </div>
                        })}
                        <div style={{color: loopStyles.colors.secondary, borderBottom: `1px solid ${loopStyles.colors.tertiary}`, paddingBottom: '2px', marginBottom: '10px'}}>Teams</div>
                        <div style={{display: 'flex', alignItems: 'center', margin: '10px 0'}}>
                            <Checkbox onChange={() => { dispatch(updateFilter({key: 'teams', filter: !filters.teams })) }} checked={filters.teams} id='teams' type='checkbox'/>
                            <CheckboxLabel htmlFor='teams'>Hide teams</CheckboxLabel>
                        </div>
                    </motion.div>
                </div>}
            </div>
            <Scrollable>
                <ContentHeader>
                    <div>Hyperloop Companies</div>
                    <CollapseButton onClick={() => { setIsHyperloopsOpen(!isHyperloopsOpen) }}>
                        {isHyperloopsOpen ? <RemoveIcon style={{ width: '20px', height: '20px' }}/> : <AddIcon style={{ width: '20px', height: '20px' }}/> }
                    </CollapseButton>
                </ContentHeader>
                <ContentContainer>
                    {isHyperloopsOpen && menuData && Object.values(menuData!).filter(filterCompany).filter((company: any) => company?.techType === 'HyperLoop').sort((a: any,b: any) => Number(a.menuSortOrder) - Number(b.menuSortOrder)).map((company: any) => 
                        <RouteFeatureGroup
                            company={company}
                            label={company.name}
                            value={company.featureID}
                            icon={`${company.featureID}.png`}
                            key={`routeFeatureGroup:${company.featureID}`}
                        />
                    )}
                </ContentContainer>
                <ContentHeader>
                    <div>Loop Companies</div>
                    <CollapseButton onClick={() => { setIsLoopsOpen(!isLoopsOpen) }}>
                        {isLoopsOpen ? <RemoveIcon style={{ width: '20px', height: '20px' }}/> : <AddIcon style={{ width: '20px', height: '20px' }}/> }
                    </CollapseButton>
                </ContentHeader>
                <ContentContainer>
                    {isLoopsOpen && menuData && Object.values(menuData!).filter(filterCompany).filter((company: any) => company?.techType === 'Loop').map((company: any) => 
                        <RouteFeatureGroup
                            company={company}
                            label={company.name}
                            value={company.featureID}
                            icon={`${company.featureID}.png`}
                            key={`routeFeatureGroup:${company.featureID}`}
                        />
                    )}
                </ContentContainer>
                <ContentHeader>
                    <div>Academic Teams</div>
                    <CollapseButton onClick={() => { setIsTeamsOpen(!isTeamsOpen) }}>
                        {isTeamsOpen ? <RemoveIcon style={{ width: '20px', height: '20px' }}/> : <AddIcon style={{ width: '20px', height: '20px' }}/> }
                    </CollapseButton>
                </ContentHeader>
                <ContentContainer>
                    {isTeamsOpen && menuData && Object.keys(menuData?.teams || {}).map((teamKey: string) => 
                        <TeamSidebarGroup
                            teams={menuData.teams[teamKey]}
                            label={teamKey}
                            value={teamKey}
                            icon={`HTT.png`}
                            key={`teamSidebarGroup:${teamKey}`}
                        />
                    )}
                </ContentContainer>
            </Scrollable>
        </motion.div>}
    </AnimatePresence>
}

const Scrollable = styled.div`
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0.4em;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

type IconButtonProps = {
    selected?: boolean
}

const IconButton = styled.div<IconButtonProps>`
    width: fit-content;
    display: flex;
    align-items: center;
    font-family: Poppins;
    white-space: nowrap;
    color: #B5B5BE;
    padding: 5px 10px;
    margin: 0 5px;
    border-radius: 8px;
    ${props => props.selected && 'background-color: rgba(0,0,0,0.2);'}
    &:hover {
        cursor: pointer;
        background-color: rgba(0,0,0,0.2);
    }
`

const CheckboxLabel = styled.label`
    color: ${loopStyles.colors.secondary};
    flex: 1;
    user-select: none;
`

const Checkbox = styled.input`
    accent-color: ${loopStyles.colors.tertiary};
    width: 16px;
    height: 16px;
    margin-right: 8px;
    transition: all 0.2s ease-in;
`

const CollapseButton = styled.div`
    border-radius: 50%;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    transition: all 0.2s ease-in;
    &:hover {
        background-color: ${loopStyles.colors.tertiary};
        color: ${loopStyles.colors.primary};
    }
`

const ContentHeader = styled.div<{spaceAbove?: boolean}>(
    {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 20px',
        color: loopStyles.colors.tertiary,
        letterSpacing: '0.2em',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '21px',
        textTransform: 'uppercase',
    }, 
    ({ spaceAbove }) => ({
        marginTop: spaceAbove ? '24px' : '0',
    })
)

const ContentContainer = styled.div({
    marginBottom: '12px'
})

export const drawerWidth = 300;
const styles = {
    root: {
        display: 'flex',
    },
    barColorPrimary: {
        backgroundColor: loopStyles.colors.primary,
    },
    childIcon: {
        // color: loopStyles.colors.secondary,
        height: '100%',
        width: '60px',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: `rgba(0,0,0,0)`
        }
    },
    regionalViewItem: {
        paddingLeft: '24px',
        height: '40px',
    },
    regionalViewLabel: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.1px',
        color: '#B5B5BE',
    },
    clearAllIcon: {
        position: CSSPosition.Absolute,
    },
    clearAllButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: loopStyles.colors.tertiary,
        width: '40px',
        height: '40px',
    },
    drawer: {
        width: drawerWidth,
        height: '100%',
        overflow: 'hidden',
        position: CSSPosition.Absolute,
        zIndex: 4,
        borderRight: '2px solid #333233',
    },
    closedDrawer: {
        width: 0,
        overflow: 'hidden',
    },
    drawerPaper: {
        width: '100%',
        maxWidth: drawerWidth,
        height: '100%',
        position: CSSPosition.Absolute,
        // zIndex: 4,
        background: 'rgba(35, 34, 36, 0.9)',
        overflow: 'hidden',
        'backdrop-filter': 'blur(6px)',
    },
    list: {
        width: '100%',
        height: '100%',
        maxWidth: drawerWidth,
        display: 'inline-block',
        // color: loopStyles.colors.primary,
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        // '&::-webkit-scrollbar-track': {
        //     '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        // },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: loopStyles.colors.tertiary,
            border: '4px solid transparent',
            borderRadius: '16px',
        },
        overflow: 'auto',
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        letterSpacing: '0.1px',
        paddingLeft: '20px',
        height: '100%',
        cursor: 'pointer',
    },
    modal: {
        opacity: 0.3
    },
    searchBar: {
        color: 'rgba(146, 146, 157, 1.0)',
        opacity: 100,
        height: '100%',
        margin: '0 10px',
        width: '100%'
    },
    selected: {
        height: '50px',
        padding: 0,
        color: '#FFFFFF',
        '& svg': {
            opacity: 0.5,
            fill: '#FFFFFF',
        },
        '&:hover': {
            color: `${loopStyles.colors.primary}`,
            // fill: `${loopStyles.colors.primary}`,
            backgroundColor: `${loopStyles.colors.secondary}`
        },
        '&:hover svg': {
            opacity: 0.9,
            fill: `black`,
        }
    },
    listItemText: {
        height: '100%',
    },
    teamIcon: {
        marginLeft: '36px'
    },
    title: {
        color: loopStyles.colors.tertiary,
        fontFamily: 'OneDay'
    },
    tooltip: {
        marginTop: '40px'
    }
};

export default withStyles(styles)(RouteSidebarLayout);