import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import loopStyles from 'common/styles'
import { CSSPosition } from 'common/constants';
import styled from 'styled-components';

export const drawerWidth = 300;

interface SidebarProps {
    isOpen: boolean,
    classes: any,
    children: any,
}

interface SidebarState {
}

class Sidebar extends React.Component<SidebarProps, SidebarState> {
    render() {
        const { children } = this.props;

        return (
            <Container>
                <Scrollable>
                    {children}
                </Scrollable>
            </Container>
        );
    }
}

const Scrollable = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const Container = styled.div`
    position: relative;
    flex: 0 0 ${drawerWidth}px;
    overflow: hidden;
    border-right: 2px solid #333233;
    &::-webkit-scrollbar {
        width: 0.4em;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`


const styles = {
    root: {
        display: 'flex',
    },
    barColorPrimary: {
        backgroundColor: loopStyles.colors.primary,
    },
    childIcon: {
        color: loopStyles.colors.secondary,
        height: '100%',
        width: '60px',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: `${loopStyles.colors.secondary}`
        }
    },
    regionalViewItem: {
        paddingLeft: '24px',
        height: '40px',
    },
    regionalViewLabel: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.1px',
        color: '#B5B5BE',
    },
    clearAllIcon: {
        position: CSSPosition.Absolute,
    },
    clearAllButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: loopStyles.colors.tertiary,
        width: '40px',
        height: '40px',
    },
    drawer: {
        width: drawerWidth,
        height: '100%',
        overflow: 'hidden',
    },
    closedDrawer: {
        width: 0,
        overflow: 'hidden',
    },
    drawerPaper: {
        width: '100%',
        maxWidth: drawerWidth,
        height: '100%',
        // zIndex: 4,
        background: 'rgba(35, 34, 36, 0.9)',
        overflow: 'hidden',
        'backdrop-filter': 'blur(6px)',
    },
    list: {
        width: '100%',
        height: '100%',
        maxWidth: drawerWidth,
        display: 'inline-block',
        // color: loopStyles.colors.primary,
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        // '&::-webkit-scrollbar-track': {
        //     '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        // },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: loopStyles.colors.tertiary,
            border: '4px solid transparent',
            borderRadius: '16px',
        },
        overflowX: 'hidden' as any,
        overflowY: 'auto' as any,
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        letterSpacing: '0.1px',
        color: '#FFFFFF',
        paddingLeft: '20px',
        height: '100%',
        cursor: 'pointer',
        '&:hover': {
            color: `${loopStyles.colors.primary}`,
            backgroundColor: `${loopStyles.colors.secondary}`
        }
    },
    modal: {
        opacity: 0.3
    },
    searchBar: {
        color: 'rgba(146, 146, 157, 1.0)',
        opacity: 100,
        height: '100%',
        margin: '0 10px',
        width: '100%'
    },
    selected: {
        height: '50px',
        padding: 0,
    },
    listItemText: {
        height: '100%',
    },
    title: {
        color: loopStyles.colors.tertiary,
        fontFamily: 'OneDay'
    },
    tooltip: {
        marginTop: '40px'
    }
};

export default withStyles(styles)(Sidebar);